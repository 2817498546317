import { TableCell as MUITableCell, TableCellProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { FC } from "react";

type Props = TableCellProps;

const useStyles = makeStyles({
  tableCell: {
    whiteSpace: "nowrap",
  },
});

export const TableCell: FC<Props> = (props) => {
  const classes = useStyles();

  return <MUITableCell className={classes.tableCell} {...props} />;
};
