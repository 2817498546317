/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type Platform = "ANDROID" | "IOS" | "WEB" | "%future added value";
export type UpdateAuctionSettingInput = {
    clientMutationId?: string | null;
    id: string;
    platform: Platform;
    floorPrice: number;
};
export type UpdateAuctionSettingMutationVariables = {
    input: UpdateAuctionSettingInput;
};
export type UpdateAuctionSettingMutationResponse = {
    readonly updateAuctionSetting: {
        readonly auctionSetting: {
            readonly id: string;
            readonly platform: Platform;
            readonly floorPrice: number;
        } | null;
    } | null;
};
export type UpdateAuctionSettingMutation = {
    readonly response: UpdateAuctionSettingMutationResponse;
    readonly variables: UpdateAuctionSettingMutationVariables;
};



/*
mutation UpdateAuctionSettingMutation(
  $input: UpdateAuctionSettingInput!
) {
  updateAuctionSetting(input: $input) {
    auctionSetting {
      id
      platform
      floorPrice
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateAuctionSettingPayload",
    "kind": "LinkedField",
    "name": "updateAuctionSetting",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuctionSetting",
        "kind": "LinkedField",
        "name": "auctionSetting",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "platform",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "floorPrice",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateAuctionSettingMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateAuctionSettingMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d501ac68fbd34e670db08f9e85552954",
    "id": null,
    "metadata": {},
    "name": "UpdateAuctionSettingMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateAuctionSettingMutation(\n  $input: UpdateAuctionSettingInput!\n) {\n  updateAuctionSetting(input: $input) {\n    auctionSetting {\n      id\n      platform\n      floorPrice\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '5cb61e3743141c192bd7ece359c40d3f';
export default node;
