import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { FC, MouseEventHandler, useCallback } from "react";

type Props = {
  open: boolean;
  onClose: () => void;
  onExecute: MouseEventHandler;
};

export const FormAlertDialog: FC<Props> = ({ open, onClose, onExecute }) => {
  const handleCancelClick = useCallback(
    (event) => {
      event.stopPropagation();
      onClose();
    },
    [onClose]
  );

  const handleExecuteClick = useCallback(
    (event) => {
      event.stopPropagation();
      onExecute(event);
    },
    [onExecute]
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>編集内容を破棄しますか？</DialogTitle>
      <DialogContent>
        <DialogContentText>
          実行を押すと入力された内容が破棄されます。
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" autoFocus onClick={handleCancelClick}>
          キャンセル
        </Button>
        <Button color="primary" onClick={handleExecuteClick}>
          破棄
        </Button>
      </DialogActions>
    </Dialog>
  );
};
