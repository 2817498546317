import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import { FC, useCallback, useMemo } from "react";
import { FragmentRef, graphql } from "react-relay";

import { MembershipRoleEditForm_membership } from "~/__relay_artifacts__/MembershipRoleEditForm_membership.graphql";
import {
  FormValues,
  MembershipRoleForm,
  schema,
} from "~/components/MembershipRoleForm";
import { extractValidationErrors } from "~/lib/mutationUtils";
import { useFragment } from "~/lib/relay-hooks";
import { useUpdateMembershipRoleMutation } from "~/mutations/UpdateMembershipRoleMutation";

type Props = {
  membershipRef: FragmentRef<MembershipRoleEditForm_membership>;
  onSubmitCompleted: () => void;
};

const membershipFragment = graphql`
  fragment MembershipRoleEditForm_membership on Membership {
    id
    role
  }
`;

export const MembershipRoleEditForm: FC<Props> = ({
  membershipRef,
  onSubmitCompleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { updateMembershipRoleMutation } = useUpdateMembershipRoleMutation();
  const membership = useFragment<MembershipRoleEditForm_membership>(
    membershipFragment,
    membershipRef
  );

  const initialValues = useMemo(
    () => ({ role: membership.role } as FormValues),
    [membership]
  );

  const onSubmit = useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const { updateMembershipRole } = await updateMembershipRoleMutation({
          id: membership.id,
          ...values,
        });
        if (!updateMembershipRole?.membership) {
          throw new Error("assertion failed");
        }
        enqueueSnackbar("メンバー役割を更新しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        const errors = extractValidationErrors<FormValues>(err);
        if (errors) setErrors(errors);
      }
    },
    [
      updateMembershipRoleMutation,
      membership.id,
      enqueueSnackbar,
      onSubmitCompleted,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      <MembershipRoleForm />
    </Formik>
  );
};
