import { FC } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { AdUnitDetailContainer } from "~/pages/AdUnitDetail";
import { AppCreateContainer } from "~/pages/AppCreate";
import { AppDetailContainer } from "~/pages/AppDetail";
import { AppEditContainer } from "~/pages/AppEdit";
import { AppListContainer } from "~/pages/AppList";
import { HomeContainer } from "~/pages/Home";
import { ProjectCreateContainer } from "~/pages/ProjectCreate";
import { ProjectEditContainer } from "~/pages/ProjectEdit";
import { PublisherReportContainer } from "~/pages/PublisherReport";
import { SigninContainer } from "~/pages/Signin";
import { SignupContainer } from "~/pages/Signup";
import { TopContainer } from "~/pages/Top";
import { UserListContainer } from "~/pages/UserList";

import { AdminRoot } from "./AdminRoot";
import { AuthenticatedRoute, UnauthenticatedRoute } from "./AuthRoutes";
import { Layout } from "./Layout";
import { ProjectRoot } from "./ProjectRoot";

type Props = {
  isSignedIn: boolean;
};

export const Routes: FC<Props> = ({ isSignedIn }) => (
  <Switch>
    <UnauthenticatedRoute
      isSignedIn={isSignedIn}
      exact
      path="/signin"
      component={SigninContainer}
    />
    <UnauthenticatedRoute
      isSignedIn={isSignedIn}
      exact
      path="/signup"
      component={SignupContainer}
    />
    <AuthenticatedRoute
      isSignedIn={isSignedIn}
      exact
      path="/"
      component={TopContainer}
    />
    <AuthenticatedRoute
      isSignedIn={isSignedIn}
      exact
      path="/users"
      render={() => (
        <AdminRoot>
          <UserListContainer />
        </AdminRoot>
      )}
    />
    <AuthenticatedRoute
      isSignedIn={isSignedIn}
      path="/projects/create"
      component={ProjectCreateContainer}
    />
    <AuthenticatedRoute
      isSignedIn={isSignedIn}
      path="/projects/:projectId"
      render={() => (
        <ProjectRoot>
          <Layout>
            <Switch>
              <Route
                exact
                path="/projects/:projectId/home"
                component={HomeContainer}
              />
              <Route
                exact
                path="/projects/:projectId/apps"
                component={AppListContainer}
              />
              <Route
                exact
                path="/projects/:projectId/apps/create"
                component={AppCreateContainer}
              />
              <Route
                exact
                path="/projects/:projectId/apps/:id/edit"
                component={AppEditContainer}
              />
              <Route
                exact
                path="/projects/:projectId/apps/:id"
                component={AppDetailContainer}
              />
              <Route
                exact
                path="/projects/:projectId/ad_units/:id"
                component={AdUnitDetailContainer}
              />
              <Route
                exact
                path="/projects/:projectId/edit"
                component={ProjectEditContainer}
              />
              <Route
                exact
                path="/projects/:projectId/publisher_report"
                component={PublisherReportContainer}
              />
              <Redirect to="/projects/:projectId/publisher_report" />
            </Switch>
          </Layout>
        </ProjectRoot>
      )}
    />
  </Switch>
);
