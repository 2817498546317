import { FC } from "react";
import { graphql } from "react-relay";
import { useParams } from "react-router";
import { useMemo } from "reactn";
import { useQuery } from "relay-hooks";

import { PublisherReport_Query } from "~/__relay_artifacts__/PublisherReport_Query.graphql";
import { PageHeader } from "~/components/PageHeader";

import AdUnitReportFormContainer from "./AdUnitReportFormContainer";

const query = graphql`
  query PublisherReport_Query(
    $projectId: ID!
    $measures: [MeasureInput!]!
    $dimensions: [DimensionInput!]!
    $filter: FilterInput!
    $orders: [OrderInput!]
  ) {
    project(id: $projectId) {
      ...AdUnitReportFormContainer_project
    }
  }
`;

const useVariables = (projectId: string) => {
  const variables = useMemo(
    () => ({
      projectId,
      measures: [],
      dimensions: [],
      orders: [],
      filter: {
        periodTill: new Date().toISOString(),
        periodSince: new Date().toISOString(),
        appId: "",
      },
    }),
    [projectId]
  );

  return { variables };
};

export const PublisherReportContainer: FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { variables } = useVariables(projectId);
  const { props, error } = useQuery<PublisherReport_Query>(query, variables);

  return (
    <>
      <PageHeader title="メディアレポート" />
      <div>
        {(() => {
          if (error) return error.message;
          if (!props) return "Loading";
          if (!props.project) return "not found";
          return <AdUnitReportFormContainer project={props.project} />;
        })()}
      </div>
    </>
  );
};
