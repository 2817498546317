import { Typography } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { FC } from "react";
import { FragmentRef, graphql } from "react-relay";
import SyntaxHighlighter from "react-syntax-highlighter";

import { AdUnitTagCard_adUnit } from "~/__relay_artifacts__/AdUnitTagCard_adUnit.graphql";
import { useFragment } from "~/lib/relay-hooks";

type Props = {
  adUnitRef: FragmentRef<AdUnitTagCard_adUnit>;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
  },
}));

const adUnitFragment = graphql`
  fragment AdUnitTagCard_adUnit on AdUnit {
    id
    embedTag
  }
`;

export const AdUnitTagCard: FC<Props> = ({ adUnitRef }) => {
  const classes = useStyles();
  const adUnit = useFragment<AdUnitTagCard_adUnit>(adUnitFragment, adUnitRef);

  return (
    <div className={classes.root}>
      <Typography variant="h6" id="modal-title">
        広告を表示するには以下のタグをサイトのbodyに追加してください
      </Typography>
      <SyntaxHighlighter language="html">{adUnit.embedTag}</SyntaxHighlighter>
    </div>
  );
};
