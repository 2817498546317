/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdUnitDetail_QueryVariables = {
    projectId: string;
    adUnitId: string;
};
export type AdUnitDetail_QueryResponse = {
    readonly project: {
        readonly adUnit: {
            readonly " $fragmentRefs": FragmentRefs<"AdUnitDetail_adUnit">;
        } | null;
    } | null;
};
export type AdUnitDetail_Query = {
    readonly response: AdUnitDetail_QueryResponse;
    readonly variables: AdUnitDetail_QueryVariables;
};



/*
query AdUnitDetail_Query(
  $projectId: ID!
  $adUnitId: ID!
) {
  project(id: $projectId) {
    adUnit(id: $adUnitId) {
      ...AdUnitDetail_adUnit
      id
    }
    id
  }
}

fragment AdUnitDetail_adUnit on AdUnit {
  id
  name
  app {
    id
  }
  auctionSettings {
    edges {
      node {
        id
        platform
        floorPrice
        ...DailyAuctionSettingListTableCard_auctionSetting
      }
    }
  }
}

fragment DailyAuctionSettingEditForm_dailyAuctionSetting on DailyAuctionSetting {
  id
  date
  floorPrice
  platform
}

fragment DailyAuctionSettingListTableCard_auctionSetting on AuctionSetting {
  id
  platform
  dailyAuctionSettings(first: 100) {
    edges {
      node {
        id
        platform
        date
        floorPrice
        ...DailyAuctionSettingEditForm_dailyAuctionSetting
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "adUnitId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "adUnitId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "platform",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "floorPrice",
  "storageKey": null
},
v7 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdUnitDetail_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "AdUnit",
            "kind": "LinkedField",
            "name": "adUnit",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AdUnitDetail_adUnit"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AdUnitDetail_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "AdUnit",
            "kind": "LinkedField",
            "name": "adUnit",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "App",
                "kind": "LinkedField",
                "name": "app",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AuctionSettingConnection",
                "kind": "LinkedField",
                "name": "auctionSettings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuctionSettingEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AuctionSetting",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": (v7/*: any*/),
                            "concreteType": "DailyAuctionSettingConnection",
                            "kind": "LinkedField",
                            "name": "dailyAuctionSettings",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "DailyAuctionSettingEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "DailyAuctionSetting",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v4/*: any*/),
                                      (v5/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "date",
                                        "storageKey": null
                                      },
                                      (v6/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "__typename",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PageInfo",
                                "kind": "LinkedField",
                                "name": "pageInfo",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": "dailyAuctionSettings(first:100)"
                          },
                          {
                            "alias": null,
                            "args": (v7/*: any*/),
                            "filters": null,
                            "handle": "connection",
                            "key": "DailyAuctionSettingListTableCard_dailyAuctionSettings",
                            "kind": "LinkedHandle",
                            "name": "dailyAuctionSettings"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2f6fd4dce4d2ac468493c39800b9e283",
    "id": null,
    "metadata": {},
    "name": "AdUnitDetail_Query",
    "operationKind": "query",
    "text": "query AdUnitDetail_Query(\n  $projectId: ID!\n  $adUnitId: ID!\n) {\n  project(id: $projectId) {\n    adUnit(id: $adUnitId) {\n      ...AdUnitDetail_adUnit\n      id\n    }\n    id\n  }\n}\n\nfragment AdUnitDetail_adUnit on AdUnit {\n  id\n  name\n  app {\n    id\n  }\n  auctionSettings {\n    edges {\n      node {\n        id\n        platform\n        floorPrice\n        ...DailyAuctionSettingListTableCard_auctionSetting\n      }\n    }\n  }\n}\n\nfragment DailyAuctionSettingEditForm_dailyAuctionSetting on DailyAuctionSetting {\n  id\n  date\n  floorPrice\n  platform\n}\n\nfragment DailyAuctionSettingListTableCard_auctionSetting on AuctionSetting {\n  id\n  platform\n  dailyAuctionSettings(first: 100) {\n    edges {\n      node {\n        id\n        platform\n        date\n        floorPrice\n        ...DailyAuctionSettingEditForm_dailyAuctionSetting\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'bb835ea29086c49a808ce965feedf64f';
export default node;
