import { FC } from "react";
import { FragmentRef, graphql } from "react-relay";

import { AuctionSettingEditButton_auctionSetting } from "~/__relay_artifacts__/AuctionSettingEditButton_auctionSetting.graphql";
import { ModalButton } from "~/components/ModalButton";
import { AuctionSettingEditForm } from "~/containers/AuctionSettingEditForm";
import { useFragment } from "~/lib/relay-hooks";

type Props = {
  auctionSettingRef: FragmentRef<AuctionSettingEditButton_auctionSetting>;
};

const auctionSettingFragment = graphql`
  fragment AuctionSettingEditButton_auctionSetting on AuctionSetting {
    ...AuctionSettingEditForm_auctionSetting
  }
`;

export const AuctionSettingEditButton: FC<Props> = ({ auctionSettingRef }) => {
  const auctionSetting = useFragment<AuctionSettingEditButton_auctionSetting>(
    auctionSettingFragment,
    auctionSettingRef
  );

  return (
    <ModalButton
      variant="outlined"
      color="primary"
      render={({ close }) => (
        <AuctionSettingEditForm
          auctionSettingRef={auctionSetting}
          onSubmitCompleted={close}
        />
      )}
    >
      編集
    </ModalButton>
  );
};
