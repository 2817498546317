import { Button, DialogContent, Grid } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import {
  FormikDateField,
  FormikNumberField,
  FormikSelectField,
} from "@vrize/vrizead-use";
import { Form, useFormikContext } from "formik";
import { FC } from "react";
import * as yup from "yup";

import { Platform } from "~/__relay_artifacts__/DailyAuctionSettingListTableCard_auctionSetting.graphql";

const StyledDialogContent = styled(DialogContent)({
  overflow: "visible",
});

export type FormValues = {
  floorPrice: number;
  date: string;
  platform: Platform;
};

export const schema = yup.object().shape({
  date: yup.string().required(),
  floorPrice: yup.number().required(),
  platform: yup.string().oneOf(["IOS", "ANDROID"]).required(),
});

const platformOptions = [
  { value: "IOS", label: "iOS" },
  { value: "ANDROID", label: "Android" },
];

export const DailyAuctionSettingForm: FC = () => {
  const { isSubmitting } = useFormikContext();

  return (
    <>
      <StyledDialogContent>
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormikDateField required fullWidth name="date" label="日付" />
            </Grid>
            <Grid item xs={12}>
              <FormikSelectField
                fullWidth
                name="platform"
                label="プラットフォーム"
                options={platformOptions}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikNumberField
                required
                fullWidth
                name="floorPrice"
                label="CPM"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
                disabled={isSubmitting}
              >
                保存する
              </Button>
            </Grid>
          </Grid>
        </Form>
      </StyledDialogContent>
    </>
  );
};
