/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Platform = "ANDROID" | "IOS" | "WEB" | "%future added value";
export type AppDetailCard_app = {
    readonly id: string;
    readonly name: string;
    readonly platform: Platform;
    readonly url: string;
    readonly createdAt: string;
    readonly updatedAt: string;
    readonly " $refType": "AppDetailCard_app";
};
export type AppDetailCard_app$data = AppDetailCard_app;
export type AppDetailCard_app$key = {
    readonly " $data"?: AppDetailCard_app$data;
    readonly " $fragmentRefs": FragmentRefs<"AppDetailCard_app">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppDetailCard_app",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "platform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    }
  ],
  "type": "App",
  "abstractKey": null
};
(node as any).hash = '34293b1674e145703655faa45df0f5df';
export default node;
