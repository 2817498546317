import { useCallback } from "react";
import { graphql, useRelayEnvironment } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  DeleteAuctionSettingInput,
  DeleteAuctionSettingMutation,
} from "~/__relay_artifacts__/DeleteAuctionSettingMutation.graphql";

type Options = {
  adUnitId: string;
};

const mutation = graphql`
  mutation DeleteAuctionSettingMutation($input: DeleteAuctionSettingInput!) {
    deleteAuctionSetting(input: $input) {
      deletedAuctionSettingId
    }
  }
`;

export const useDeleteAuctionSettingMutation = ({ adUnitId }: Options) => {
  const environment = useRelayEnvironment();

  const deleteAuctionSettingMutation = useCallback(
    (input: DeleteAuctionSettingInput) =>
      commitMutation<DeleteAuctionSettingMutation>(environment, {
        mutation,
        variables: { input },
        configs: [
          {
            type: "RANGE_DELETE",
            parentID: adUnitId,
            connectionKeys: [{ key: "AuctionSettingList_auctionSettings" }],
            pathToConnection: ["adUnits", "auctionSettings"],
            deletedIDFieldName: "deletedAuctionSettingId",
          },
        ],
      }),
    [adUnitId, environment]
  );

  return { deleteAuctionSettingMutation };
};
