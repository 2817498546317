/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MembershipList_QueryVariables = {
    projectId: string;
    first?: number | null;
    after?: string | null;
};
export type MembershipList_QueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"MembershipList_viewer">;
    };
    readonly project: {
        readonly " $fragmentRefs": FragmentRefs<"MembershipList_project">;
    } | null;
};
export type MembershipList_Query = {
    readonly response: MembershipList_QueryResponse;
    readonly variables: MembershipList_QueryVariables;
};



/*
query MembershipList_Query(
  $projectId: ID!
  $first: Int
  $after: String
) {
  viewer {
    ...MembershipList_viewer
    id
  }
  project(id: $projectId) {
    ...MembershipList_project
    id
  }
}

fragment MembershipList_project on Project {
  id
  viewerCanAdministrate
  memberships(first: $first, after: $after) {
    totalCount
    edges {
      node {
        id
        role
        createdAt
        user {
          id
          email
        }
        ...MembershipRoleEditButton_membership
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment MembershipList_viewer on User {
  id
  email
  role
}

fragment MembershipRoleEditButton_membership on Membership {
  ...MembershipRoleEditForm_membership
}

fragment MembershipRoleEditForm_membership on Membership {
  id
  role
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MembershipList_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MembershipList_viewer"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MembershipList_project"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "MembershipList_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "viewerCanAdministrate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "MembershipConnection",
            "kind": "LinkedField",
            "name": "memberships",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "MembershipEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Membership",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v7/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "MembershipList_memberships",
            "kind": "LinkedHandle",
            "name": "memberships"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "499150ea7bc8a7e13d41af644cb9092c",
    "id": null,
    "metadata": {},
    "name": "MembershipList_Query",
    "operationKind": "query",
    "text": "query MembershipList_Query(\n  $projectId: ID!\n  $first: Int\n  $after: String\n) {\n  viewer {\n    ...MembershipList_viewer\n    id\n  }\n  project(id: $projectId) {\n    ...MembershipList_project\n    id\n  }\n}\n\nfragment MembershipList_project on Project {\n  id\n  viewerCanAdministrate\n  memberships(first: $first, after: $after) {\n    totalCount\n    edges {\n      node {\n        id\n        role\n        createdAt\n        user {\n          id\n          email\n        }\n        ...MembershipRoleEditButton_membership\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment MembershipList_viewer on User {\n  id\n  email\n  role\n}\n\nfragment MembershipRoleEditButton_membership on Membership {\n  ...MembershipRoleEditForm_membership\n}\n\nfragment MembershipRoleEditForm_membership on Membership {\n  id\n  role\n}\n"
  }
};
})();
(node as any).hash = 'e818f92b688f19fc797ce6c1d8f25e47';
export default node;
