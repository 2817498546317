import { Button, Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useOpenState } from "@vrize/vrizead-use";
import { FC } from "react";

import {
  AuctionSettingList,
  Props as AuctionSettingListProps,
} from "./AuctionSettingList";

type Props = {
  adUnitRef: AuctionSettingListProps["adUnitRef"];
};

const useStyles = makeStyles({
  paper: {
    position: "absolute",
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
});

export const AuctionSettingListButton: FC<Props> = ({ adUnitRef }) => {
  const classes = useStyles();
  const [modalOpen, openModal, closeModal] = useOpenState();

  return (
    <>
      <Button variant="text" color="primary" onClick={openModal}>
        設定
      </Button>
      <Modal open={modalOpen} onClose={closeModal}>
        <div className={classes.paper}>
          <AuctionSettingList adUnitRef={adUnitRef} />
        </div>
      </Modal>
    </>
  );
};
