/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Platform = "ANDROID" | "IOS" | "WEB" | "%future added value";
export type CreateDailyAuctionSettingInput = {
    clientMutationId?: string | null;
    auctionSettingId: string;
    platform: Platform;
    floorPrice: number;
    date: string;
};
export type CreateDailyAuctionSettingMutationVariables = {
    input: CreateDailyAuctionSettingInput;
};
export type CreateDailyAuctionSettingMutationResponse = {
    readonly createDailyAuctionSetting: {
        readonly dailyAuctionSettingEdge: {
            readonly node: {
                readonly id: string;
                readonly date: string;
                readonly floorPrice: number;
                readonly platform: Platform;
                readonly " $fragmentRefs": FragmentRefs<"DailyAuctionSettingEditForm_dailyAuctionSetting">;
            } | null;
        } | null;
    } | null;
};
export type CreateDailyAuctionSettingMutation = {
    readonly response: CreateDailyAuctionSettingMutationResponse;
    readonly variables: CreateDailyAuctionSettingMutationVariables;
};



/*
mutation CreateDailyAuctionSettingMutation(
  $input: CreateDailyAuctionSettingInput!
) {
  createDailyAuctionSetting(input: $input) {
    dailyAuctionSettingEdge {
      node {
        id
        date
        floorPrice
        platform
        ...DailyAuctionSettingEditForm_dailyAuctionSetting
      }
    }
  }
}

fragment DailyAuctionSettingEditForm_dailyAuctionSetting on DailyAuctionSetting {
  id
  date
  floorPrice
  platform
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "floorPrice",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "platform",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateDailyAuctionSettingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateDailyAuctionSettingPayload",
        "kind": "LinkedField",
        "name": "createDailyAuctionSetting",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DailyAuctionSettingEdge",
            "kind": "LinkedField",
            "name": "dailyAuctionSettingEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DailyAuctionSetting",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "DailyAuctionSettingEditForm_dailyAuctionSetting"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateDailyAuctionSettingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateDailyAuctionSettingPayload",
        "kind": "LinkedField",
        "name": "createDailyAuctionSetting",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DailyAuctionSettingEdge",
            "kind": "LinkedField",
            "name": "dailyAuctionSettingEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DailyAuctionSetting",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "48e2159c83e0c5aeb26b854733b04ad5",
    "id": null,
    "metadata": {},
    "name": "CreateDailyAuctionSettingMutation",
    "operationKind": "mutation",
    "text": "mutation CreateDailyAuctionSettingMutation(\n  $input: CreateDailyAuctionSettingInput!\n) {\n  createDailyAuctionSetting(input: $input) {\n    dailyAuctionSettingEdge {\n      node {\n        id\n        date\n        floorPrice\n        platform\n        ...DailyAuctionSettingEditForm_dailyAuctionSetting\n      }\n    }\n  }\n}\n\nfragment DailyAuctionSettingEditForm_dailyAuctionSetting on DailyAuctionSetting {\n  id\n  date\n  floorPrice\n  platform\n}\n"
  }
};
})();
(node as any).hash = 'b8738b4fb034a69f8e5d268bc4e81ce1';
export default node;
