import { useCallback } from "react";
import { graphql, useRelayEnvironment } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  CreateMembershipInput,
  CreateMembershipMutation,
} from "~/__relay_artifacts__/CreateMembershipMutation.graphql";

const mutation = graphql`
  mutation CreateMembershipMutation($input: CreateMembershipInput!) {
    createMembership(input: $input) {
      membershipEdge {
        node {
          id
          role
          createdAt
          user {
            email
          }
        }
      }
    }
  }
`;

export const useCreateMembershipMutation = () => {
  const environment = useRelayEnvironment();

  const createMembershipMutation = useCallback(
    (input: CreateMembershipInput) =>
      commitMutation<CreateMembershipMutation>(environment, {
        mutation,
        variables: { input },
        configs: [
          {
            type: "RANGE_ADD",
            parentID: input.projectId,
            connectionInfo: [
              {
                key: "MembershipList_memberships",
                rangeBehavior: "prepend",
              },
            ],
            edgeName: "membershipEdge",
          },
        ],
      }),
    [environment]
  );

  return { createMembershipMutation };
};
