import { useCallback } from "react";
import { graphql, useRelayEnvironment } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  CreateProjectInput,
  CreateProjectMutation,
} from "~/__relay_artifacts__/CreateProjectMutation.graphql";

const mutation = graphql`
  mutation CreateProjectMutation($input: CreateProjectInput!) {
    createProject(input: $input) {
      project {
        id
        name
      }
    }
  }
`;

export const useCreateProjectMutation = () => {
  const environment = useRelayEnvironment();

  const createProjectMutation = useCallback(
    (input: CreateProjectInput) =>
      commitMutation<CreateProjectMutation>(environment, {
        mutation,
        variables: { input },
      }),
    [environment]
  );

  return { createProjectMutation };
};
