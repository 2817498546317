import { Button, ButtonProps, Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useOpenState } from "@vrize/vrizead-use";
import {
  KeyboardEventHandler,
  MouseEventHandler,
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from "react";

import { FormAlertDialog } from "./FormAlertDialog";

type RenderProps = {
  open: () => void;
  close: () => void;
};

type Props = {
  render: (props: RenderProps) => ReactNode;
  enableAlert?: boolean;
} & ButtonProps;

const useStyles = makeStyles({
  paper: {
    position: "absolute",
    // backgroundColor: theme.palette.background.paper,
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    maxHeight: `90%`,
    overflow: "scroll",
  },
  button: {
    whiteSpace: "nowrap",
    margin: 3,
  },
});

export const ModalButton = forwardRef<HTMLButtonElement, Props>(
  ({ children, render, enableAlert = true, ...buttonProps }, ref) => {
    const classes = useStyles();
    const [modalOpen, openModal, closeModal] = useOpenState();
    const [alertOpen, openAlert, closeAlert] = useOpenState();
    const [isTouched, setIsTouched] = useState(false);

    // NOTE: reset isTouched state if modal is opened or closed
    useEffect(() => {
      setIsTouched(false);
    }, [modalOpen]);

    const handleButtonClick = useCallback<MouseEventHandler>(
      (event) => {
        event.stopPropagation();
        openModal();
      },
      [openModal]
    );

    const handleModalBodyClick = useCallback<MouseEventHandler>((event) => {
      event.stopPropagation();
      setIsTouched(true);
    }, []);

    const handleModalBodyKeyPress = useCallback<KeyboardEventHandler>(
      (event) => {
        event.stopPropagation();
        setIsTouched(true);
      },
      []
    );

    const handleModalClose = useCallback<MouseEventHandler>(
      (event) => {
        event.stopPropagation();
        isTouched && enableAlert ? openAlert() : closeModal();
      },
      [closeModal, enableAlert, isTouched, openAlert]
    );

    const handleExecuteClick = useCallback<MouseEventHandler>(
      (event) => {
        event.stopPropagation();
        closeAlert();
        closeModal();
      },
      [closeAlert, closeModal]
    );

    return (
      <>
        <Button
          ref={ref}
          className={classes.button}
          onClick={handleButtonClick}
          {...buttonProps}
        >
          {children}
        </Button>
        <Modal open={modalOpen} onClose={handleModalClose}>
          <div
            className={classes.paper}
            onClick={handleModalBodyClick}
            onKeyPress={handleModalBodyKeyPress}
          >
            {render({ close: closeModal, open: openModal })}
            <FormAlertDialog
              open={alertOpen}
              onClose={closeAlert}
              onExecute={handleExecuteClick}
            />
          </div>
        </Modal>
      </>
    );
  }
);
