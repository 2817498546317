import { useCallback } from "react";
import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { useRelayEnvironment } from "relay-hooks";

import {
  CreateDailyAuctionSettingInput,
  CreateDailyAuctionSettingMutation,
} from "~/__relay_artifacts__/CreateDailyAuctionSettingMutation.graphql";

const mutation = graphql`
  mutation CreateDailyAuctionSettingMutation(
    $input: CreateDailyAuctionSettingInput!
  ) {
    createDailyAuctionSetting(input: $input) {
      dailyAuctionSettingEdge {
        node {
          id
          date
          floorPrice
          platform
          ...DailyAuctionSettingEditForm_dailyAuctionSetting
        }
      }
    }
  }
`;

export const useCreateDailyAuctionSettingMutation = () => {
  const environment = useRelayEnvironment();

  const createDailyAuctionSettingMutation = useCallback(
    (input: CreateDailyAuctionSettingInput) =>
      commitMutation<CreateDailyAuctionSettingMutation>(environment, {
        mutation,
        variables: {
          input,
        },
        configs: [
          {
            type: "RANGE_ADD",
            parentID: input.auctionSettingId,
            connectionInfo: [
              {
                key: "DailyAuctionSettingListTableCard_dailyAuctionSettings",
                rangeBehavior: "prepend",
              },
            ],
            edgeName: "dailyAuctionSettingEdge",
          },
        ],
      }),
    [environment]
  );

  return { createDailyAuctionSettingMutation };
};
