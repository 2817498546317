import { List, Typography } from "@material-ui/core";
import { FC, useMemo } from "react";
import { FragmentRef, graphql } from "react-relay";

import { ProjectList_viewer } from "~/__relay_artifacts__/ProjectList_viewer.graphql";
import { ProjectListItem } from "~/components/ProjectListItem";
import { useFragment } from "~/lib/relay-hooks";

type Props = {
  viewerRef: FragmentRef<ProjectList_viewer>;
};

const userFragment = graphql`
  fragment ProjectList_viewer on User {
    email
    projects(
      first: 2147483647 # max GraphQLInt
    ) @connection(key: "ProjectList_projects") {
      edges {
        node {
          id
          ...ProjectListItem_project
        }
      }
    }
  }
`;

export const ProjectList: FC<Props> = ({ viewerRef }) => {
  const viewer = useFragment<ProjectList_viewer>(userFragment, viewerRef);

  const projects = useMemo(() => {
    const edges = viewer.projects?.edges || [];
    return edges.map((edge) => {
      if (!edge?.node) throw new Error("assertion failed");
      return edge.node;
    });
  }, [viewer.projects]);

  return projects.length === 0 ? (
    <Typography variant="subtitle1">
      プロジェクトが作成されておりません
    </Typography>
  ) : (
    <List>
      {projects.map((project) => (
        <ProjectListItem number={1} key={project.id} projectRef={project} />
      ))}
    </List>
  );
};
