import { useCallback } from "react";
import { graphql, useRelayEnvironment } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  DeleteMembershipInput,
  DeleteMembershipMutation,
} from "~/__relay_artifacts__/DeleteMembershipMutation.graphql";

type Options = {
  projectId: string;
};

const mutation = graphql`
  mutation DeleteMembershipMutation($input: DeleteMembershipInput!) {
    deleteMembership(input: $input) {
      deletedMembershipId
    }
  }
`;

export const useDeleteMembershipMutation = ({ projectId }: Options) => {
  const environment = useRelayEnvironment();

  const deleteMembershipMutation = useCallback(
    (input: DeleteMembershipInput) =>
      commitMutation<DeleteMembershipMutation>(environment, {
        mutation,
        variables: { input },
        configs: [
          {
            type: "RANGE_DELETE",
            parentID: projectId,
            connectionKeys: [{ key: "MembershipList_memberships" }],
            pathToConnection: ["project", "memberships"],
            deletedIDFieldName: "deletedMembershipId",
          },
        ],
      }),
    [environment, projectId]
  );

  return { deleteMembershipMutation };
};
