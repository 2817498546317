import { useCallback } from "react";
import { graphql, useRelayEnvironment } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  UpdateAdUnitInput,
  UpdateAdUnitMutation,
} from "~/__relay_artifacts__/UpdateAdUnitMutation.graphql";

const mutation = graphql`
  mutation UpdateAdUnitMutation($input: UpdateAdUnitInput!) {
    updateAdUnit(input: $input) {
      adUnit {
        ...AdUnitEditForm_adUnit
      }
    }
  }
`;

export const useUpdateAdUnitMutation = () => {
  const environment = useRelayEnvironment();

  const updateAdUnitMutation = useCallback(
    (input: UpdateAdUnitInput) =>
      commitMutation<UpdateAdUnitMutation>(environment, {
        mutation,
        variables: { input },
      }),
    [environment]
  );

  return { updateAdUnitMutation };
};
