import { useCallback } from "react";
import { graphql, useRelayEnvironment } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  CreateUserInput,
  CreateUserMutation,
} from "~/__relay_artifacts__/CreateUserMutation.graphql";

const mutation = graphql`
  mutation CreateUserMutation($input: CreateUserInput!) {
    createUser(input: $input) {
      userEdge {
        node {
          id
          email
          role
        }
      }
    }
  }
`;

export const useCreateUserMutation = () => {
  const environment = useRelayEnvironment();

  const createUserMutation = useCallback(
    (input: CreateUserInput) =>
      commitMutation<CreateUserMutation>(environment, {
        mutation,
        variables: { input },
        configs: [
          {
            type: "RANGE_ADD",
            parentID: "client:root",
            connectionInfo: [
              {
                key: "UserListTableCard_users",
                rangeBehavior: "prepend",
              },
            ],
            edgeName: "userEdge",
          },
        ],
      }),
    [environment]
  );

  return { createUserMutation };
};
