import { useCallback } from "react";
import { graphql, useRelayEnvironment } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  DeleteAdUnitInput,
  DeleteAdUnitMutation,
} from "~/__relay_artifacts__/DeleteAdUnitMutation.graphql";

type Options = {
  appId: string;
};

const mutation = graphql`
  mutation DeleteAdUnitMutation($input: DeleteAdUnitInput!) {
    deleteAdUnit(input: $input) {
      deletedAdUnitId
    }
  }
`;

export const useDeleteAdUnitMutation = ({ appId }: Options) => {
  const environment = useRelayEnvironment();

  const deleteAdUnitMutation = useCallback(
    (input: DeleteAdUnitInput) =>
      commitMutation<DeleteAdUnitMutation>(environment, {
        mutation,
        variables: { input },
        configs: [
          {
            type: "RANGE_DELETE",
            parentID: appId,
            connectionKeys: [
              {
                key: "AdUnitListTableCard_adUnits",
              },
            ],
            pathToConnection: ["app", "adUnits"],
            deletedIDFieldName: "deletedAdUnitId",
          },
        ],
      }),
    [appId, environment]
  );

  return { deleteAdUnitMutation };
};
