import { useCallback } from "react";
import { graphql, useRelayEnvironment } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  CreateAuctionSettingInput,
  CreateAuctionSettingMutation,
} from "~/__relay_artifacts__/CreateAuctionSettingMutation.graphql";

const mutation = graphql`
  mutation CreateAuctionSettingMutation($input: CreateAuctionSettingInput!) {
    createAuctionSetting(input: $input) {
      auctionSettingEdge {
        node {
          id
          platform
          floorPrice
        }
      }
    }
  }
`;

export const useCreateAuctionSettingMutation = () => {
  const environment = useRelayEnvironment();

  const createAuctionSettingMutation = useCallback(
    (input: CreateAuctionSettingInput) =>
      commitMutation<CreateAuctionSettingMutation>(environment, {
        mutation,
        variables: { input },
        configs: [
          {
            type: "RANGE_ADD",
            parentID: input.adUnitId,
            connectionInfo: [
              {
                key: "AuctionSettingList_auctionSettings",
                rangeBehavior: "prepend",
              },
            ],
            edgeName: "auctionSettingEdge",
          },
        ],
      }),
    [environment]
  );

  return { createAuctionSettingMutation };
};
