import { CssBaseline, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { FC } from "react";

import { ProjectHeaderContainer } from "~/containers/ProjectHeader";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
  },
  main: {
    [theme.breakpoints.down("xs")]: {
      width: `100%`,
    },
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${240}px)`,
    },
  },
  content: {},
  toolbar: theme.mixins.toolbar,
}));

export const Layout: FC = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CssBaseline />
      <ProjectHeaderContainer />
      <main className={classes.main}>
        <div className={classes.toolbar} />
        <div className={classes.content}>{children}</div>
      </main>
    </div>
  );
};
