import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { FC, useMemo } from "react";
import { FragmentRef, graphql } from "react-relay";

import { AuctionSettingList_adUnit } from "~/__relay_artifacts__/AuctionSettingList_adUnit.graphql";
import { AuctionSettingCreateButton } from "~/containers/AuctionSettingCreateButton";
import { AuctionSettingDeleteButton } from "~/containers/AuctionSettingDeleteButton";
import { AuctionSettingEditButton } from "~/containers/AuctionSettingEditButton";
import { useFragment } from "~/lib/relay-hooks";

export type Props = {
  adUnitRef: FragmentRef<AuctionSettingList_adUnit>;
};

const useStyles = makeStyles({
  root: {},
  table: {
    minWidth: 700,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  spacer: {
    flex: "1 1 100%",
  },
  title: {
    flex: "0 0 auto",
  },
  actions: {
    display: "flex",
  },
});

const adUnitFragment = graphql`
  fragment AuctionSettingList_adUnit on AdUnit {
    id
    auctionSettings(first: 100)
      @connection(key: "AuctionSettingList_auctionSettings") {
      edges {
        node {
          id
          platform
          floorPrice
          ...AuctionSettingEditButton_auctionSetting
        }
      }
    }
  }
`;

export const AuctionSettingList: FC<Props> = ({ adUnitRef }) => {
  const classes = useStyles();
  const adUnit = useFragment<AuctionSettingList_adUnit>(
    adUnitFragment,
    adUnitRef
  );

  const auctionSettings = useMemo(() => {
    const edges = adUnit.auctionSettings?.edges || [];
    return edges.map((edge) => {
      if (!edge?.node) throw new Error("assertion failed");
      return edge.node;
    });
  }, [adUnit.auctionSettings?.edges]);

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <div className={classes.title}>
          <Typography variant="subtitle1" color="inherit">
            オークション設定一覧
          </Typography>
        </div>
        <div className={classes.actions}>
          <AuctionSettingCreateButton adUnitId={adUnit.id} />
        </div>
      </Toolbar>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>プラットフォーム</TableCell>
              <TableCell>最低価格</TableCell>
              <TableCell>アクション</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {auctionSettings.map((auctionSetting) => (
              <TableRow key={auctionSetting.id} hover>
                <TableCell>{auctionSetting.platform}</TableCell>
                <TableCell>{auctionSetting.floorPrice}</TableCell>
                <TableCell>
                  <AuctionSettingEditButton
                    auctionSettingRef={auctionSetting}
                  />
                  <AuctionSettingDeleteButton
                    auctionSettingId={auctionSetting.id}
                    adUnitId={adUnit.id}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
};
