import {
  Icon,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import { FC, useCallback, useMemo } from "react";
import { FragmentRef, graphql } from "react-relay";

import { DailyAuctionSettingListTableCard_auctionSetting } from "~/__relay_artifacts__/DailyAuctionSettingListTableCard_auctionSetting.graphql";
import { ConfirmButton } from "~/components/atoms/ConfirmButton";
import { DailyAuctionSettingCreateForm } from "~/containers/DailyAuctionSettingCreateForm";
import { DailyAuctionSettingEditForm } from "~/containers/DailyAuctionSettingEditForm";
import { useFragment } from "~/lib/relay-hooks";
import { useDeleteDailyAuctionSettingMutation } from "~/mutations/DeleteDailyAuctionSettingMutation";

import { DialogButton } from "./DialogButton";

type Props = {
  auctionSettingRef: FragmentRef<DailyAuctionSettingListTableCard_auctionSetting>;
};

const fragment = graphql`
  fragment DailyAuctionSettingListTableCard_auctionSetting on AuctionSetting {
    id
    platform
    dailyAuctionSettings(first: 100)
      @connection(
        key: "DailyAuctionSettingListTableCard_dailyAuctionSettings"
      ) {
      edges {
        node {
          id
          platform
          date
          floorPrice
          ...DailyAuctionSettingEditForm_dailyAuctionSetting
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  paper: {
    margin: theme.spacing(3),
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    width: "100%",
    overflowX: "auto",
  },
  actions: {
    display: "flex",
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
}));

export const DailyAuctionSettingListTableCard: FC<Props> = ({
  auctionSettingRef,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const auctionSetting =
    useFragment<DailyAuctionSettingListTableCard_auctionSetting>(
      fragment,
      auctionSettingRef
    );
  const { deleteDailyAuctionSettingMutation } =
    useDeleteDailyAuctionSettingMutation({
      auctionSettingId: auctionSetting.id,
    });

  const dailyAuctionSettings = useMemo(() => {
    const edges = auctionSetting.dailyAuctionSettings.edges || [];
    return edges.map((edge) => {
      if (!edge?.node) throw new Error("assertion failed");
      return edge?.node;
    });
  }, [auctionSetting]);

  const handleDeleteClick = useCallback(
    async (dailyAuctionSettingId: string) => {
      try {
        const { deleteDailyAuctionSetting } =
          await deleteDailyAuctionSettingMutation({
            dailyAuctionSettingId,
          });
        if (!deleteDailyAuctionSetting?.deletedDailyAuctionSettingId) {
          throw new Error("assertion failed");
        }
        enqueueSnackbar("CPMスケジュールを削除しました", {
          variant: "success",
        });
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      }
    },
    [deleteDailyAuctionSettingMutation, enqueueSnackbar]
  );

  return (
    <Paper className={classes.paper}>
      <Toolbar>
        <Typography variant="subtitle1" color="inherit">
          CPMスケジュール一覧
        </Typography>
        <div>
          <DialogButton
            title="CPMスケジュール作成"
            render={({ close }) => (
              <DailyAuctionSettingCreateForm
                auctionSettingId={auctionSetting.id}
                platform={auctionSetting.platform}
                onSubmitCompleted={close}
              />
            )}
          >
            <Icon className={classes.leftIcon}>add</Icon>
            <span>作成する</span>
          </DialogButton>
        </div>
      </Toolbar>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>日付</TableCell>
              <TableCell>CPM</TableCell>
              <TableCell>OS</TableCell>
              <TableCell>アクション</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dailyAuctionSettings.map((dailyAuctionSetting) => (
              <TableRow key={dailyAuctionSetting.id}>
                <TableCell>{dailyAuctionSetting.date}</TableCell>
                <TableCell>{dailyAuctionSetting.floorPrice}</TableCell>
                <TableCell>{dailyAuctionSetting.platform}</TableCell>
                <TableCell>
                  <DialogButton
                    title="CPM編集"
                    variant="outlined"
                    color="primary"
                    render={({ close }) => (
                      <DailyAuctionSettingEditForm
                        dailyAuctionSettingRef={dailyAuctionSetting}
                        onSubmitCompleted={close}
                      />
                    )}
                  >
                    <span>編集</span>
                  </DialogButton>
                  <ConfirmButton
                    variant="outlined"
                    color="secondary"
                    confirmTitle="このCPMを削除しますか？"
                    onAgree={async (changeDialog) => {
                      await handleDeleteClick(dailyAuctionSetting.id);
                      changeDialog(false);
                    }}
                  >
                    削除
                  </ConfirmButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
};
