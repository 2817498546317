import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { FC, useCallback } from "react";
import { useParams } from "react-router";

import { AdUnitForm, FormValues, schema } from "~/components/AdUnitForm";
import { useCreateAdUnitMutation } from "~/mutations/CreateAdUnitMutation";

type Props = {
  onSubmitCompleted: () => void;
};

const initialValues: FormValues = {
  name: "",
  adPosition: "FOOTER",
  width: 0,
  height: 0,
  frequencyCapEnabled: false,
  passbackValidationEnabled: true,
};

export const AdUnitCreateForm: FC<Props> = ({ onSubmitCompleted }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id: appId } = useParams<{ projectId: string; id: string }>();
  const { createAdUnitMutation } = useCreateAdUnitMutation();
  const formErrorHandler = useFormErrorHandler();

  const onSubmit = useCallback(
    async (values, { setErrors }) => {
      const input = { appId, ...values };
      try {
        const { createAdUnit } = await createAdUnitMutation(input);
        if (!createAdUnit?.adUnitEdge) throw new Error("assertion failed");
        enqueueSnackbar("広告枠を作成しました", { variant: "success" });
        onSubmitCompleted();
      } catch (error) {
        formErrorHandler(error, setErrors);
      }
    },
    [
      appId,
      createAdUnitMutation,
      enqueueSnackbar,
      formErrorHandler,
      onSubmitCompleted,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      <AdUnitForm />
    </Formik>
  );
};
