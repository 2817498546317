import * as DateFns from "date-fns";
import { Formik, FormikConfig } from "formik";
import { FC } from "react";
import { RelayRefetchProp, createRefetchContainer, graphql } from "react-relay";
import useMount from "react-use/lib/useMount";
import { useCallback, useGlobal, useMemo } from "reactn";

import { AdUnitReportFormContainer_project } from "~/__relay_artifacts__/AdUnitReportFormContainer_project.graphql";

import { AdUnitReportForm, FormValues, schema } from "./AdUnitReportForm";
import { dimensions, measures, orders } from "./constants";

type Props = {
  relay: RelayRefetchProp;
  project: AdUnitReportFormContainer_project;
};

/** レポートの取得期間を返す（当日3日前00:00:00から当日の前日23:59:59まで） */
const getPeriods = () => {
  const currentDate = new Date();
  const startOfToday = DateFns.startOfDay(currentDate);
  const startOfThreeDaysAgo = DateFns.subDays(startOfToday, 3);
  return {
    periodSince: startOfThreeDaysAgo.toISOString(),
    periodTill: DateFns.startOfToday().toISOString(),
  };
};

const AdUnitReportFormContainer: FC<Props> = ({ relay, project }) => {
  const [caches, setCaches] = useGlobal("publisherReportCache");
  const currentCache = caches[project.id];
  const { periodSince, periodTill } = getPeriods();
  const isCached = !!currentCache;

  const initialValues = useMemo(
    () =>
      currentCache || {
        adUnitIds: [],
        os: ["IOS", "ANDROID"],
        periodSince: periodSince,
        periodTill: periodTill,
      },
    [currentCache, periodSince, periodTill]
  );

  const periodSinceMinDate = useMemo(
    () => DateFns.startOfDay(new Date(2020, 1, 1)).toISOString(),
    []
  );

  const periodTillMaxDate = useMemo(() => periodTill, [periodTill]);

  const refetch = useCallback(
    (values: FormValues, force: boolean, callback?: (err: any) => void) => {
      const variables = {
        measures,
        dimensions,
        orders,
        filter: values,
      };
      relay.refetch(variables, undefined, callback, { force });
    },
    [relay]
  );

  const onSubmit = useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      setCaches({ ...caches, [project.id]: values });
      return new Promise<void>((resolve) =>
        refetch(values, true, (error: any) => {
          if (!!error) setErrors(error);
          resolve();
        })
      );
    },
    [caches, project.id, refetch, setCaches]
  );

  useMount(() => {
    if (!currentCache) return;
    // NOTE: use relay cache
    refetch(currentCache, false);
  });

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {(formikHelpers) => (
        <AdUnitReportForm
          {...formikHelpers}
          isFormCached={isCached}
          project={project}
          periodSinceMinDate={periodSinceMinDate}
          periodTillMaxDate={periodTillMaxDate}
        />
      )}
    </Formik>
  );
};

export default createRefetchContainer(
  AdUnitReportFormContainer,
  {
    project: graphql`
      fragment AdUnitReportFormContainer_project on Project {
        id
        apps {
          edges {
            node {
              adUnits {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
        ...ReportList_project
      }
    `,
  },
  graphql`
    query AdUnitReportFormContainer_Query(
      $projectId: ID!
      $measures: [MeasureInput!]!
      $dimensions: [DimensionInput!]!
      $filter: FilterInput!
      $orders: [OrderInput!]
    ) {
      project(id: $projectId) {
        ...AdUnitReportFormContainer_project
      }
    }
  `
);
