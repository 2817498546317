/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Platform = "ANDROID" | "IOS" | "WEB" | "%future added value";
export type DailyAuctionSettingEditForm_dailyAuctionSetting = {
    readonly id: string;
    readonly date: string;
    readonly floorPrice: number;
    readonly platform: Platform;
    readonly " $refType": "DailyAuctionSettingEditForm_dailyAuctionSetting";
};
export type DailyAuctionSettingEditForm_dailyAuctionSetting$data = DailyAuctionSettingEditForm_dailyAuctionSetting;
export type DailyAuctionSettingEditForm_dailyAuctionSetting$key = {
    readonly " $data"?: DailyAuctionSettingEditForm_dailyAuctionSetting$data;
    readonly " $fragmentRefs": FragmentRefs<"DailyAuctionSettingEditForm_dailyAuctionSetting">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DailyAuctionSettingEditForm_dailyAuctionSetting",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "floorPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "platform",
      "storageKey": null
    }
  ],
  "type": "DailyAuctionSetting",
  "abstractKey": null
};
(node as any).hash = '01324493af2f7267ab7b595dc3bb1cc5';
export default node;
