import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import {
  FormikNumberField,
  FormikSelectField,
  FormikSwitchField,
  FormikTextField,
} from "@vrize/vrizead-use";
import { Form, useFormikContext } from "formik";
import { FC } from "react";
import * as yup from "yup";

import { AdPosition } from "~/__relay_artifacts__/CreateAdUnitMutation.graphql";

export type FormValues = {
  name: string;
  width: number;
  height: number;
  adPosition: AdPosition;
  frequencyCapEnabled: boolean;
  tagId?: string;
  passbackValidationEnabled: boolean;
  passbackIosTag?: string;
  passbackAndroidTag?: string;
};

const useStyles = makeStyles({
  card: {
    maxWidth: 600,
  },
});

export const schema = yup.object().shape({
  name: yup.string().required(),
  width: yup.number().required(),
  height: yup.number().required(),
  adPosition: yup.string().oneOf(["UNKNOWN", "FOOTER"]).required(),
  frequencyCapEnabled: yup.boolean().required(),
  tagId: yup.string(),
  passbackIosTag: yup.string(),
  passbackAndroidTag: yup.string(),
  passbackValidationEnabled: yup.boolean().required(),
});

const adPositionOptions = [
  { value: "UNKNOWN", label: "UNKNOWN (コンテンツ挿し込み型)" },
  { value: "FOOTER", label: "FOOTER (オーバーレイのような固定表示)" },
];

export const AdUnitForm: FC = () => {
  const classes = useStyles();
  const { values, isSubmitting } = useFormikContext<FormValues>();

  return (
    <Form>
      <Card className={classes.card}>
        <CardContent>
          <CardHeader title="広告枠作成" />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormikTextField required fullWidth name="name" label="名前" />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                fullWidth
                name="tagId"
                label="広告枠ID"
                helperText="この値はサービス全体でユニークである必要があります。生成時に入力がなければ自動的にユニークな値が割り振られます。"
              />
            </Grid>
            <Grid item xs={6}>
              <FormikNumberField required fullWidth name="width" label="横幅" />
            </Grid>
            <Grid item xs={6}>
              <FormikNumberField
                required
                fullWidth
                name="height"
                label="高さ"
              />
            </Grid>
            <Grid item xs={12}>
              <FormikSelectField
                fullWidth
                name="adPosition"
                label="表示位置"
                options={adPositionOptions}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                fullWidth
                multiline
                rows={5}
                margin="normal"
                name="passbackIosTag"
                label="iOSのパスバッグタグ"
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                fullWidth
                multiline
                rows={5}
                margin="normal"
                name="passbackAndroidTag"
                label="Androidのパスバッグタグ"
              />
            </Grid>
            <Grid item xs={12}>
              <FormikSwitchField
                name="passbackValidationEnabled"
                label="パスバックタグのバリデーションをOFFにする"
                checked={!values.passbackValidationEnabled}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className="md-divider-border md-divider-border--top">
          <Button
            fullWidth
            type="submit"
            color="primary"
            variant="contained"
            disabled={isSubmitting}
          >
            保存する
          </Button>
        </CardActions>
      </Card>
    </Form>
  );
};
