/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type Platform = "ANDROID" | "IOS" | "WEB" | "%future added value";
export type UpdateDailyAuctionSettingInput = {
    clientMutationId?: string | null;
    id: string;
    platform: Platform;
    floorPrice: number;
    date: string;
};
export type UpdateDailyAuctionSettingMutationVariables = {
    input: UpdateDailyAuctionSettingInput;
};
export type UpdateDailyAuctionSettingMutationResponse = {
    readonly updateDailyAuctionSetting: {
        readonly dailyAuctionSetting: {
            readonly id: string;
            readonly date: string;
            readonly floorPrice: number;
            readonly platform: Platform;
        } | null;
    } | null;
};
export type UpdateDailyAuctionSettingMutation = {
    readonly response: UpdateDailyAuctionSettingMutationResponse;
    readonly variables: UpdateDailyAuctionSettingMutationVariables;
};



/*
mutation UpdateDailyAuctionSettingMutation(
  $input: UpdateDailyAuctionSettingInput!
) {
  updateDailyAuctionSetting(input: $input) {
    dailyAuctionSetting {
      id
      date
      floorPrice
      platform
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateDailyAuctionSettingPayload",
    "kind": "LinkedField",
    "name": "updateDailyAuctionSetting",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DailyAuctionSetting",
        "kind": "LinkedField",
        "name": "dailyAuctionSetting",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "floorPrice",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "platform",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateDailyAuctionSettingMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateDailyAuctionSettingMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d664c93a99d58c37c0a14a194c1f1301",
    "id": null,
    "metadata": {},
    "name": "UpdateDailyAuctionSettingMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateDailyAuctionSettingMutation(\n  $input: UpdateDailyAuctionSettingInput!\n) {\n  updateDailyAuctionSetting(input: $input) {\n    dailyAuctionSetting {\n      id\n      date\n      floorPrice\n      platform\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e4ba5c0971c51262aeee8e674b252efc';
export default node;
