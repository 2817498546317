import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { FormikSelectField, FormikTextField } from "@vrize/vrizead-use";
import { Form, useFormikContext } from "formik";
import { FC } from "react";
import * as yup from "yup";

export type FormValues = {
  email: string;
  role: "ADMIN" | "PUBLISHER" | "ADVERTISER" | "GUEST";
};

const useStyles = makeStyles({
  card: {
    maxWidth: 600,
  },
});

export const schema = yup.object().shape({
  email: yup.string().required(),
  role: yup
    .string()
    .oneOf(["ADMIN", "PUBLISHER", "ADVERTISER", "GUEST"])
    .required(),
});

const roleOptions = [
  { value: "", label: "未選択" },
  { value: "ADMIN", label: "管理者" },
  { value: "PUBLISHER", label: "メディア(運営者)" },
  { value: "ADVERTISER", label: "広告主" },
  { value: "GUEST", label: "ゲスト" },
];

export const MembershipForm: FC = () => {
  const classes = useStyles();
  const { isSubmitting } = useFormikContext();

  return (
    <Form>
      <Card className={classes.card}>
        <CardContent>
          <CardHeader title="メンバー作成" />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormikTextField fullWidth name="email" label="メールアドレス" />
            </Grid>
            <Grid item xs={12}>
              <FormikSelectField
                required
                fullWidth
                name="role"
                label="役割"
                options={roleOptions}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid item xs={12}>
            <Button
              fullWidth
              type="submit"
              color="primary"
              variant="contained"
              disabled={isSubmitting}
            >
              保存する
            </Button>
          </Grid>
        </CardActions>
      </Card>
    </Form>
  );
};
