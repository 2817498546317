import {
  Icon,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as DateFns from "date-fns";
import { useSnackbar } from "notistack";
import { FC, useCallback, useMemo } from "react";
import { graphql } from "react-relay";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

import { AdUnitListTableCard_app } from "~/__relay_artifacts__/AdUnitListTableCard_app.graphql";
import { AppDetail_app } from "~/__relay_artifacts__/AppDetail_app.graphql";
import { AdUnitTagCard } from "~/components/AdUnitTagCard";
import { AuctionSettingListButton } from "~/components/AuctionSettingListButton";
import { ModalButton } from "~/components/ModalButton";
import { ConfirmButton } from "~/components/atoms/ConfirmButton";
import { TableCell } from "~/components/atoms/TableCell";
import { AdUnitCreateForm } from "~/containers/AdUnitCreateForm";
import { AdUnitEditButton } from "~/containers/AdUnitEditButton";
import { useFragment } from "~/lib/relay-hooks";
import { useDeleteAdUnitMutation } from "~/mutations/DeleteAdUnitMutation";

type Props = {
  appRef: AppDetail_app;
};

const appFragment = graphql`
  fragment AdUnitListTableCard_app on App {
    id
    adUnits(first: $first, after: $after, orderBy: $orderBy)
      @connection(key: "AdUnitListTableCard_adUnits", filters: []) {
      edges {
        node {
          id
          name
          createdAt
          updatedAt
          width
          height
          adPosition
          tagId
          embedTag
          frequencyCapEnabled
          ...AdUnitEditButton_adUnit
          ...AdUnitTagCard_adUnit
          ...AuctionSettingList_adUnit
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    width: "100%",
    overflowX: "auto",
  },
}));

export const AdUnitListTableCard: FC<Props> = ({ appRef }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const app = useFragment<AdUnitListTableCard_app>(appFragment, appRef);
  const { projectId } = useParams<{ projectId: string }>();
  const { deleteAdUnitMutation } = useDeleteAdUnitMutation({ appId: app.id });

  const adUnits = useMemo(() => {
    const edges = app.adUnits.edges || [];
    return edges.map((edge) => {
      if (!edge?.node) throw new Error("assertion failed");
      return edge?.node;
    });
  }, [app.adUnits.edges]);

  const handleAdUnitDeleteClick = useCallback(
    async (adUnitId: string) => {
      try {
        const { deleteAdUnit } = await deleteAdUnitMutation({ adUnitId });
        if (!deleteAdUnit?.deletedAdUnitId) throw new Error("assertion failed");
        enqueueSnackbar(`広告枠を削除しました。`, { variant: "success" });
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      }
    },
    [deleteAdUnitMutation, enqueueSnackbar]
  );

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography variant="subtitle1" color="inherit">
          登録済み広告枠の一覧
        </Typography>
        <ModalButton
          render={({ close }) => <AdUnitCreateForm onSubmitCompleted={close} />}
        >
          <Icon>add</Icon>
          新規作成
        </ModalButton>
      </Toolbar>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>名前</TableCell>
              <TableCell>タグ</TableCell>
              <TableCell>オークション設定</TableCell>
              <TableCell>広告ID</TableCell>
              <TableCell>横幅</TableCell>
              <TableCell>高さ</TableCell>
              <TableCell>フォーマット</TableCell>
              <TableCell>表示位置</TableCell>
              <TableCell>作成日</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {adUnits.map((adUnit) => (
              <TableRow key={adUnit.id}>
                <TableCell>
                  <Link to={`/projects/${projectId}/ad_units/${adUnit.id}`}>
                    {adUnit.name}
                  </Link>
                </TableCell>
                <TableCell>
                  <ModalButton
                    enableAlert={false}
                    render={() => <AdUnitTagCard adUnitRef={adUnit} />}
                  >
                    タグ
                  </ModalButton>
                </TableCell>
                <TableCell>
                  <AuctionSettingListButton adUnitRef={adUnit} />
                </TableCell>
                <TableCell>{adUnit.tagId}</TableCell>
                <TableCell>{adUnit.width}</TableCell>
                <TableCell>{adUnit.height}</TableCell>
                <TableCell>{adUnit.adPosition}</TableCell>
                <TableCell>
                  {DateFns.format(
                    DateFns.parseISO(adUnit.createdAt),
                    "yyyy/MM/dd"
                  )}
                </TableCell>
                <TableCell>
                  <AdUnitEditButton adUnitRef={adUnit}>編集</AdUnitEditButton>
                  <ConfirmButton
                    variant="outlined"
                    color="secondary"
                    confirmTitle="この広告枠を削除しますか？"
                    onAgree={() => handleAdUnitDeleteClick(adUnit.id)}
                  >
                    削除
                  </ConfirmButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
};
