import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import { FC, useCallback } from "react";
import { useParams } from "react-router";

import {
  FormValues,
  MembershipForm,
  schema,
} from "~/components/MembershipForm";
import { extractValidationErrors } from "~/lib/mutationUtils";
import { useCreateMembershipMutation } from "~/mutations/CreateMembershipMutation";

type Props = {
  onSubmitCompleted: () => void;
};

const initialValues = {
  email: "",
  role: "GUEST",
} as const;

export const MembershipCreateForm: FC<Props> = ({ onSubmitCompleted }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { projectId } = useParams<{ projectId: string }>();
  const { createMembershipMutation } = useCreateMembershipMutation();

  const onSubmit = useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const { createMembership } = await createMembershipMutation({
          projectId,
          ...values,
        });
        if (!createMembership?.membershipEdge) {
          throw new Error("assertion failed");
        }
        enqueueSnackbar("メンバーを追加しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        enqueueSnackbar("メンバーの追加に失敗しました", { variant: "error" });
        const errors = extractValidationErrors<FormValues>(err);
        if (errors) setErrors(errors);
      }
    },
    [createMembershipMutation, projectId, enqueueSnackbar, onSubmitCompleted]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      <MembershipForm />
    </Formik>
  );
};
