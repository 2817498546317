import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import { FC, useCallback } from "react";
import { useHistory, useParams } from "react-router";

import { AppForm, FormValues, validationSchema } from "~/components/AppForm";
import { extractValidationErrors } from "~/lib/mutationUtils";
import { useCreateAppMutation } from "~/mutations/CreateAppMutation";

const initialValues: FormValues = {
  name: "",
  url: "",
  platform: "WEB",
  frequencyCapEnabled: false,
  geoLocationEnabled: false,
};

export const AppCreateForm: FC = () => {
  const history = useHistory();
  const { projectId } = useParams<{ projectId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const { createAppMutation } = useCreateAppMutation();

  const onSubmit = useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const { createApp } = await createAppMutation({ projectId, ...values });
        const newApp = createApp?.app;
        if (!newApp) throw new Error("assertion failed");
        enqueueSnackbar("アプリを作成しました", { variant: "success" });
        history.push(`/projects/${projectId}/apps/${newApp.id}`);
      } catch (err) {
        const errors = extractValidationErrors<FormValues>(err);
        if (errors) setErrors(errors);
      }
    },
    [createAppMutation, projectId, enqueueSnackbar, history]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      <AppForm />
    </Formik>
  );
};
