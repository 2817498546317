export const measures = [
  { value: "DATE" },
  { value: "OS" },
  { value: "IMPRESSION" },
  { value: "CLICK" },
  { value: "INSTALL" },
  { value: "NET_BUDGET_CONSUMPTION" },
  { value: "CTR" },
  { value: "NET_CPI" },
];

export const dimensions = [{ value: "OS" }, { value: "DATE" }];

export const orders = [{ value: "DATE" }, { value: "OS" }];
