import {
  Button,
  List,
  ListItemText,
  Modal,
  TextField,
  Typography,
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { ArrowDropDown as ArrowDropDownIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { useOpenState } from "@vrize/vrizead-use";
import { isEmpty } from "ramda";
import { ChangeEventHandler, FC, useCallback, useMemo, useState } from "react";
import { createFragmentContainer, graphql } from "react-relay";

import { ProjectSelectionButton_project } from "~/__relay_artifacts__/ProjectSelectionButton_project.graphql";
import { ProjectSelectionButton_viewer } from "~/__relay_artifacts__/ProjectSelectionButton_viewer.graphql";
import { LinkButton } from "~/components/atoms/LinkButton";
import { LinkListItem } from "~/components/atoms/LinkListItem";
import { containsInsensitive } from "~/lib/containsInsensitive";

type Props = {
  project: ProjectSelectionButton_project;
  viewer: ProjectSelectionButton_viewer;
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  paper: {
    position: "absolute",
    minWidth: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  list: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    height: 500,
  },
  navLink: {
    position: "relative",
    display: "block",
    textDecoration: "none",
    "&:hover,&:focus,&:visited,&": {
      color: "#FFFFFF",
    },
  },
}));

const ProjectManageModal: FC<Props> = ({ project, viewer }) => {
  const classes = useStyles();
  const [value, setValue] = useState("");
  const [modalOpen, openModal, closeModal] = useOpenState();

  const projects = useMemo(() => {
    if (!viewer.projects.edges) throw new Error("assertion error");
    return viewer.projects.edges
      .map((edge) => {
        if (!edge?.node) throw new Error("assertion failed");
        return edge.node;
      })
      .filter((node) => containsInsensitive(value, node.name));
  }, [value, viewer.projects.edges]);

  const handleTextFieldChange = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >((e) => {
    setValue(e.target.value);
  }, []);

  return (
    <>
      <Button color="inherit" onClick={openModal}>
        <Typography color="inherit">{project.name}</Typography>
        <ArrowDropDownIcon />
      </Button>
      <Modal open={modalOpen} onClose={closeModal}>
        <div className={classes.paper}>
          <Typography color="inherit" variant="subtitle2">
            プロジェクトを変更する
          </Typography>
          <TextField
            fullWidth
            label="絞り込み検索"
            value={value}
            onChange={handleTextFieldChange}
          />
          <List className={classes.list}>
            {isEmpty(projects) ? (
              <p>条件に一致するプロジェクトが見つかりません</p>
            ) : (
              projects.map((projectNode) => (
                <LinkListItem
                  button
                  key={projectNode.id}
                  to={`/projects/${projectNode.id}/home`}
                >
                  <ListItemText primary={projectNode.name} />
                </LinkListItem>
              ))
            )}
          </List>
          {viewer.role === "ADMIN" && (
            <LinkButton
              color="primary"
              className={classes.button}
              to={`/projects/create`}
            >
              新規プロジェクト作成
            </LinkButton>
          )}
        </div>
      </Modal>
    </>
  );
};

export default createFragmentContainer(ProjectManageModal, {
  project: graphql`
    fragment ProjectSelectionButton_project on Project {
      id
      name
    }
  `,
  viewer: graphql`
    fragment ProjectSelectionButton_viewer on User {
      role
      projects {
        edges {
          node {
            id
            name
            viewerCanAdministrate
          }
        }
      }
    }
  `,
});
