import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as R from "ramda";
import { FC, useCallback, useMemo } from "react";
import { FragmentRef, graphql } from "react-relay";

import { AdUnitEditForm_adUnit } from "~/__relay_artifacts__/AdUnitEditForm_adUnit.graphql";
import { AdUnitForm, FormValues, schema } from "~/components/AdUnitForm";
import { useFragment } from "~/lib/relay-hooks";
import { useUpdateAdUnitMutation } from "~/mutations/UpdateAdUnitMutation";

type Props = {
  adUnitRef: FragmentRef<AdUnitEditForm_adUnit>;
  onSubmitCompleted: () => void;
};

const adUnitFragment = graphql`
  fragment AdUnitEditForm_adUnit on AdUnit {
    id
    name
    width
    height
    adPosition
    frequencyCapEnabled
    tagId
    passbackIosTag
    passbackAndroidTag
  }
`;

export const AdUnitEditForm: FC<Props> = ({ adUnitRef, onSubmitCompleted }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { updateAdUnitMutation } = useUpdateAdUnitMutation();
  const formErrorHandler = useFormErrorHandler();
  const adUnit = useFragment<AdUnitEditForm_adUnit>(adUnitFragment, adUnitRef);

  const initialValues = useMemo(() => {
    return {
      ...R.pick(
        [
          "name",
          "width",
          "height",
          "adPosition",
          "frequencyCapEnabled",
          "tagId",
          "passbackIosTag",
          "passbackAndroidTag",
        ],
        adUnit
      ),
      passbackValidationEnabled: true,
    };
  }, [adUnit]);

  const handleSubmit = useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const { tagId, ...restValues } = values;
        if (!tagId) throw new Error("assertion failed");
        const { updateAdUnit } = await updateAdUnitMutation({
          id: adUnit.id,
          tagId,
          ...restValues,
        });
        if (!updateAdUnit?.adUnit) throw new Error("assertion failed");
        enqueueSnackbar("広告枠を更新しました", { variant: "success" });
        onSubmitCompleted();
      } catch (error) {
        formErrorHandler(error, setErrors);
      }
    },
    [
      updateAdUnitMutation,
      adUnit.id,
      enqueueSnackbar,
      onSubmitCompleted,
      formErrorHandler,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      <AdUnitForm />
    </Formik>
  );
};
