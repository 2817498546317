import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as R from "ramda";
import { FC, useCallback, useMemo } from "react";
import { FragmentRef, graphql } from "react-relay";

import { DailyAuctionSettingEditForm_dailyAuctionSetting } from "~/__relay_artifacts__/DailyAuctionSettingEditForm_dailyAuctionSetting.graphql";
import {
  DailyAuctionSettingForm,
  FormValues,
  schema,
} from "~/components/DailyAuctionSettingForm";
import { useFragment } from "~/lib/relay-hooks";
import { useUpdateDailyAuctionSettingMutation } from "~/mutations/UpdateDailyAuctionSettingMutation";

type Props = {
  dailyAuctionSettingRef: FragmentRef<DailyAuctionSettingEditForm_dailyAuctionSetting>;
  onSubmitCompleted: () => void;
};

const fragment = graphql`
  fragment DailyAuctionSettingEditForm_dailyAuctionSetting on DailyAuctionSetting {
    id
    date
    floorPrice
    platform
  }
`;

export const DailyAuctionSettingEditForm: FC<Props> = ({
  dailyAuctionSettingRef,
  onSubmitCompleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dailyAuctionSetting =
    useFragment<DailyAuctionSettingEditForm_dailyAuctionSetting>(
      fragment,
      dailyAuctionSettingRef
    );

  const { updateDailyAuctionSettingMutation } =
    useUpdateDailyAuctionSettingMutation();

  const initialValues = useMemo<FormValues>(
    () => ({
      date: dailyAuctionSetting.date,
      floorPrice: dailyAuctionSetting.floorPrice,
      platform: dailyAuctionSetting.platform,
    }),
    [dailyAuctionSetting]
  );

  const onSubmit = useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      const { date, floorPrice, platform } = values;
      if (R.isNil(floorPrice) || R.isNil(date)) {
        throw new Error("assertion failed");
      }

      try {
        const input = {
          id: dailyAuctionSetting.id,
          floorPrice: floorPrice,
          date: date,
          platform: platform,
        };

        const { updateDailyAuctionSetting } =
          await updateDailyAuctionSettingMutation(input);
        if (!updateDailyAuctionSetting?.dailyAuctionSetting) {
          throw new Error("assertion failed");
        }
        enqueueSnackbar("CPMスケジュールを更新しました", {
          variant: "success",
        });
        onSubmitCompleted();
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
        setErrors(err);
      }
    },
    [
      enqueueSnackbar,
      onSubmitCompleted,
      dailyAuctionSetting.id,
      updateDailyAuctionSettingMutation,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      <DailyAuctionSettingForm />
    </Formik>
  );
};
