import { FC } from "react";
import { graphql } from "react-relay";
import { useParams } from "react-router";
import { useQuery } from "relay-hooks";

import { AdUnitDetail_Query } from "~/__relay_artifacts__/AdUnitDetail_Query.graphql";
import { AdUnitDetail } from "~/components/AdUnitDetail";

const query = graphql`
  query AdUnitDetail_Query($projectId: ID!, $adUnitId: ID!) {
    project(id: $projectId) {
      adUnit(id: $adUnitId) {
        ...AdUnitDetail_adUnit
      }
    }
  }
`;

export const AdUnitDetailContainer: FC = () => {
  const { id, projectId } = useParams<{ id: string; projectId: string }>();
  const { props, error } = useQuery<AdUnitDetail_Query>(query, {
    projectId,
    adUnitId: id,
  });

  if (error) return <div>{error.message}</div>;
  if (!props) return <div>Loading...</div>;
  if (!props.project?.adUnit) return <div>not found</div>;
  return <AdUnitDetail adUnitRef={props.project.adUnit} />;
};
