import { FC } from "react";
import { FragmentRef, graphql } from "react-relay";

import { ReportList_project } from "~/__relay_artifacts__/ReportList_project.graphql";
import { ReportList as ReportListComponent } from "~/components/ReportList";
import { useFragment } from "~/lib/relay-hooks";

type Props = {
  shouldDisplay: boolean;
  measureFields: string[];
  projectRef: FragmentRef<ReportList_project>;
};

const projectFragment = graphql`
  fragment ReportList_project on Project {
    id
    report(
      measures: $measures
      dimensions: $dimensions
      filter: $filter
      orders: $orders
    ) {
      measures
    }
  }
`;

export const ReportList: FC<Props> = ({
  shouldDisplay,
  measureFields,
  projectRef,
}) => {
  const project = useFragment<ReportList_project>(projectFragment, projectRef);

  return shouldDisplay ? (
    <ReportListComponent project={project} measureFields={measureFields} />
  ) : null;
};
