import { FC } from "react";
import { graphql } from "react-relay";
import { useParams } from "react-router";
import { useQuery } from "relay-hooks";

import { Home_Query } from "~/__relay_artifacts__/Home_Query.graphql";

import { PageContent } from "./PageContent";

const query = graphql`
  query Home_Query($projectId: ID!, $first: Int, $after: String) {
    viewer {
      ...MembershipList_viewer
    }
    project(id: $projectId) {
      id
      ...MembershipList_project
    }
  }
`;

export const HomeContainer: FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { props, error } = useQuery<Home_Query>(
    query,
    { projectId: projectId, first: 100 },
    { networkCacheConfig: { force: true } }
  );

  if (error) return <div>{error.message}</div>;
  if (!props) return <div>Loading...</div>;
  if (!props.project || !props.viewer) return <div>not found</div>;
  return <PageContent project={props.project} viewer={props.viewer} />;
};
