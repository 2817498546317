import "@firebase/auth";

import firebase from "@firebase/app";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { PowerSettingsNew as PowerSettingsNewIcon } from "@material-ui/icons";
import { FC } from "react";
import { useAsyncFn } from "react-use";

export const LogoutButton: FC = () => {
  const [{ loading }, logout] = useAsyncFn(async () => {
    if (!firebase.auth) throw new Error("firebase.auth is not set");
    return firebase
      .auth()
      .signOut()
      .then(() => window.location.replace(`/signin`));
  });

  return (
    <ListItem button color="inherit" onClick={logout} disabled={loading}>
      <ListItemIcon>
        <PowerSettingsNewIcon />
      </ListItemIcon>
      <ListItemText primary="ログアウト" />
    </ListItem>
  );
};
