import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import { FC, useCallback, useMemo } from "react";
import { FragmentRef, graphql } from "react-relay";
import { useHistory } from "react-router";

import { ProjectEditForm_project } from "~/__relay_artifacts__/ProjectEditForm_project.graphql";
import { FormValues, ProjectForm, schema } from "~/components/ProjectForm";
import { extractValidationErrors } from "~/lib/mutationUtils";
import { useFragment } from "~/lib/relay-hooks";
import { useUpdateProjectMutation } from "~/mutations/UpdateProjectMutation";

type Props = {
  projectRef: FragmentRef<ProjectEditForm_project>;
};

const projectFragment = graphql`
  fragment ProjectEditForm_project on Project {
    id
    name
  }
`;

export const ProjectEditForm: FC<Props> = ({ projectRef }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { updateProjectMutation } = useUpdateProjectMutation();
  const project = useFragment<ProjectEditForm_project>(
    projectFragment,
    projectRef
  );

  const initialValues = useMemo(() => ({ name: project.name }), [project.name]);

  const onSubmit = useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const { updateProject } = await updateProjectMutation({
          id: project.id,
          name: values.name,
        });
        if (!updateProject?.project) throw new Error("assertion failed");
        enqueueSnackbar("プロジェクトを更新しました", { variant: "success" });
        history.push(`/projects/${project.id}/home`);
      } catch (err) {
        const errors = extractValidationErrors<FormValues>(err);
        if (errors) setErrors(errors);
      }
    },
    [enqueueSnackbar, history, project.id, updateProjectMutation]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      <ProjectForm />
    </Formik>
  );
};
