/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdPosition = "ABOVE_THE_FOLD" | "BELOW_THE_FOLD" | "DEPRECATED" | "FOOTER" | "FULL_SCREEN" | "HEADER" | "SIDEBAR" | "UNKNOWN" | "%future added value";
export type FrequencyCapInterval = "DAY" | "FIFTEEN_MINUTES" | "FIVE_MINUTES" | "HOUR" | "MINUTE" | "MONTH" | "NONE" | "SIX_HOURS" | "TWELVE_HOURS" | "TWO_HOURS" | "WEEK" | "YEAR" | "%future added value";
export type UpdateAdUnitInput = {
    clientMutationId?: string | null;
    frequencyCapAmount?: number | null;
    frequencyCapInterval?: FrequencyCapInterval | null;
    frequencyCapEnabled: boolean;
    id: string;
    name: string;
    width: number;
    height: number;
    adPosition: AdPosition;
    tagId: string;
    passbackIosTag?: string | null;
    passbackAndroidTag?: string | null;
    passbackValidationEnabled: boolean;
};
export type UpdateAdUnitMutationVariables = {
    input: UpdateAdUnitInput;
};
export type UpdateAdUnitMutationResponse = {
    readonly updateAdUnit: {
        readonly adUnit: {
            readonly " $fragmentRefs": FragmentRefs<"AdUnitEditForm_adUnit">;
        } | null;
    } | null;
};
export type UpdateAdUnitMutation = {
    readonly response: UpdateAdUnitMutationResponse;
    readonly variables: UpdateAdUnitMutationVariables;
};



/*
mutation UpdateAdUnitMutation(
  $input: UpdateAdUnitInput!
) {
  updateAdUnit(input: $input) {
    adUnit {
      ...AdUnitEditForm_adUnit
      id
    }
  }
}

fragment AdUnitEditForm_adUnit on AdUnit {
  id
  name
  width
  height
  adPosition
  frequencyCapEnabled
  tagId
  passbackIosTag
  passbackAndroidTag
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateAdUnitMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAdUnitPayload",
        "kind": "LinkedField",
        "name": "updateAdUnit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdUnit",
            "kind": "LinkedField",
            "name": "adUnit",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AdUnitEditForm_adUnit"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateAdUnitMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAdUnitPayload",
        "kind": "LinkedField",
        "name": "updateAdUnit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdUnit",
            "kind": "LinkedField",
            "name": "adUnit",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "width",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "height",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "adPosition",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "frequencyCapEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tagId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "passbackIosTag",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "passbackAndroidTag",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5d436593f464f68e8febfc200d84e6af",
    "id": null,
    "metadata": {},
    "name": "UpdateAdUnitMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateAdUnitMutation(\n  $input: UpdateAdUnitInput!\n) {\n  updateAdUnit(input: $input) {\n    adUnit {\n      ...AdUnitEditForm_adUnit\n      id\n    }\n  }\n}\n\nfragment AdUnitEditForm_adUnit on AdUnit {\n  id\n  name\n  width\n  height\n  adPosition\n  frequencyCapEnabled\n  tagId\n  passbackIosTag\n  passbackAndroidTag\n}\n"
  }
};
})();
(node as any).hash = 'a91859b9717760c5efe1077ee2dfa15a';
export default node;
