/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type MembershipRole = "ADMIN" | "ADVERTISER" | "GUEST" | "PUBLISHER" | "%future added value";
export type UpdateMembershipRoleInput = {
    clientMutationId?: string | null;
    id: string;
    role: MembershipRole;
};
export type UpdateMembershipRoleMutationVariables = {
    input: UpdateMembershipRoleInput;
};
export type UpdateMembershipRoleMutationResponse = {
    readonly updateMembershipRole: {
        readonly membership: {
            readonly id: string;
            readonly role: MembershipRole;
        } | null;
    } | null;
};
export type UpdateMembershipRoleMutation = {
    readonly response: UpdateMembershipRoleMutationResponse;
    readonly variables: UpdateMembershipRoleMutationVariables;
};



/*
mutation UpdateMembershipRoleMutation(
  $input: UpdateMembershipRoleInput!
) {
  updateMembershipRole(input: $input) {
    membership {
      id
      role
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateMembershipRolePayload",
    "kind": "LinkedField",
    "name": "updateMembershipRole",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Membership",
        "kind": "LinkedField",
        "name": "membership",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateMembershipRoleMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateMembershipRoleMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "62f9c6208ccd0405574f76c154cdb787",
    "id": null,
    "metadata": {},
    "name": "UpdateMembershipRoleMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateMembershipRoleMutation(\n  $input: UpdateMembershipRoleInput!\n) {\n  updateMembershipRole(input: $input) {\n    membership {\n      id\n      role\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'ce96756f23018dec6ee9013a77d26bc2';
export default node;
