/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Platform = "ANDROID" | "IOS" | "WEB" | "%future added value";
export type AuctionSettingList_adUnit = {
    readonly id: string;
    readonly auctionSettings: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly platform: Platform;
                readonly floorPrice: number;
                readonly " $fragmentRefs": FragmentRefs<"AuctionSettingEditButton_auctionSetting">;
            } | null;
        } | null> | null;
    };
    readonly " $refType": "AuctionSettingList_adUnit";
};
export type AuctionSettingList_adUnit$data = AuctionSettingList_adUnit;
export type AuctionSettingList_adUnit$key = {
    readonly " $data"?: AuctionSettingList_adUnit$data;
    readonly " $fragmentRefs": FragmentRefs<"AuctionSettingList_adUnit">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "auctionSettings"
        ]
      }
    ]
  },
  "name": "AuctionSettingList_adUnit",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "auctionSettings",
      "args": null,
      "concreteType": "AuctionSettingConnection",
      "kind": "LinkedField",
      "name": "__AuctionSettingList_auctionSettings_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AuctionSettingEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AuctionSetting",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "platform",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "floorPrice",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AuctionSettingEditButton_auctionSetting"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AdUnit",
  "abstractKey": null
};
})();
(node as any).hash = '70dbbce0c61e93e26cfa5a76577e9dc3';
export default node;
