import { useCallback } from "react";
import { graphql, useRelayEnvironment } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  CreateAdUnitInput,
  CreateAdUnitMutation,
} from "~/__relay_artifacts__/CreateAdUnitMutation.graphql";

const mutation = graphql`
  mutation CreateAdUnitMutation($input: CreateAdUnitInput!) {
    createAdUnit(input: $input) {
      adUnitEdge {
        node {
          id
          name
          createdAt
          updatedAt
          width
          height
          adPosition
          tagId
          embedTag
          frequencyCapEnabled
          ...AdUnitEditButton_adUnit
          ...AdUnitTagCard_adUnit
          ...AuctionSettingList_adUnit
        }
      }
    }
  }
`;

export const useCreateAdUnitMutation = () => {
  const environment = useRelayEnvironment();

  const createAdUnitMutation = useCallback(
    (input: CreateAdUnitInput) =>
      commitMutation<CreateAdUnitMutation>(environment, {
        mutation,
        variables: { input },
        configs: [
          {
            type: "RANGE_ADD",
            parentID: input.appId,
            connectionInfo: [
              {
                key: "AdUnitListTableCard_adUnits",
                rangeBehavior: "prepend",
              },
            ],
            edgeName: "adUnitEdge",
          },
        ],
      }),
    [environment]
  );

  return { createAdUnitMutation };
};
