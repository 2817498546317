import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { FormikDateField, FormikMultipleSelectField } from "@vrize/vrizead-use";
import * as DateFns from "date-fns";
import { Form, FormikProps } from "formik";
import { FC, useEffect, useMemo, useState } from "react";
import * as yup from "yup";

import { AdUnitReportFormContainer_project } from "~/__relay_artifacts__/AdUnitReportFormContainer_project.graphql";
import { ReportList } from "~/containers/ReportList";

import { measures } from "./constants";

export type FormValues = {
  periodTill: string;
  periodSince: string;
  adUnitIds: string[];
};

type Props = {
  isFormCached: boolean;
  project: AdUnitReportFormContainer_project;
  periodSinceMinDate: string;
  periodTillMaxDate: string;
} & FormikProps<FormValues>;

export const schema = yup.object().shape({
  periodSince: yup.string().required(),
  periodTill: yup.string().required(),
  adUnitIds: yup.array().required().min(1, "1つ以上選択してください"),
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
  },
  formControl: {
    minWidth: 150,
  },
  textField: {
    margin: 0,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing(0.25),
  },
  circularProgressWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "220px",
  },
}));

export const AdUnitReportForm: FC<Props> = ({
  isFormCached,
  project,
  values,
  isSubmitting,
  periodSinceMinDate,
  periodTillMaxDate,
  setFieldValue,
}) => {
  const classes = useStyles();
  const [haveBeenSubmitted, setHaveBeenSubmitted] = useState(false);

  useEffect(() => {
    if (isSubmitting === false) return;
    setHaveBeenSubmitted(true);
  }, [isSubmitting]);

  useEffect(() => {
    const since = new Date(values.periodSince);
    const till = new Date(values.periodTill);
    if (since > till) setFieldValue("periodTill", since);
  }, [setFieldValue, values.periodSince, values.periodTill]);

  const maxDate = useMemo(() => {
    const since = new Date(values.periodSince);
    const tillMax = new Date(periodTillMaxDate);
    if (since < DateFns.subMonths(tillMax, 1)) {
      const date = DateFns.addMonths(since, 1);
      setFieldValue("periodTill", date);
      return date;
    } else {
      return tillMax;
    }
  }, [periodTillMaxDate, setFieldValue, values.periodSince]);

  const adUnitsNodes = useMemo(() => {
    const edges =
      project.apps &&
      project.apps.edges &&
      project.apps.edges
        .map((edge) => {
          return (
            edge && edge.node && edge.node.adUnits && edge.node.adUnits.edges
          );
        })
        .flatMap((edges) => edges);
    if (!edges) throw new Error("assertion failed");
    return edges.map((edge) => {
      const node = edge && edge.node;
      if (!node) throw new Error("assertion failed");
      return node;
    });
  }, [project.apps]);

  const adUnitIdsOptions = useMemo(
    () => adUnitsNodes.map((node) => ({ value: node.id, label: node.name })),
    [adUnitsNodes]
  );

  return (
    <div className={classes.root}>
      <Form>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormikMultipleSelectField
                  fullWidth
                  name="adUnitIds"
                  label="広告ユニットを選択"
                  options={adUnitIdsOptions}
                  formControlClassName={classes.formControl}
                />
              </Grid>
              <Grid item xs={6}>
                <FormikDateField
                  fullWidth
                  name="periodSince"
                  label="開始日"
                  format="M月 d日"
                  minDate={periodSinceMinDate}
                  maxDate={periodTillMaxDate}
                />
              </Grid>
              <Grid item xs={6}>
                <FormikDateField
                  fullWidth
                  name="periodTill"
                  label="終了日"
                  format="M月 d日"
                  minDate={values.periodSince}
                  maxDate={maxDate}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button
              fullWidth
              color="primary"
              type="submit"
              variant="contained"
              disabled={isSubmitting}
            >
              表示
            </Button>
          </CardActions>
        </Card>
      </Form>
      {isSubmitting ? (
        <div className={classes.circularProgressWrapper}>
          <CircularProgress variant="indeterminate" size="60px" />
        </div>
      ) : (
        <ReportList
          // NOTE: Do not disply the list if form has never been submitted
          shouldDisplay={isFormCached || haveBeenSubmitted}
          projectRef={project}
          measureFields={measures.map((m) => m.value)}
        />
      )}
    </div>
  );
};
