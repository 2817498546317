import { FC } from "react";
import { FragmentRef, graphql } from "react-relay";

import { AdUnitEditButton_adUnit } from "~/__relay_artifacts__/AdUnitEditButton_adUnit.graphql";
import { ModalButton } from "~/components/ModalButton";
import { AdUnitEditForm } from "~/containers/AdUnitEditForm";
import { useFragment } from "~/lib/relay-hooks";

type Props = {
  adUnitRef: FragmentRef<AdUnitEditButton_adUnit>;
};

const adUnitFragment = graphql`
  fragment AdUnitEditButton_adUnit on AdUnit {
    ...AdUnitEditForm_adUnit
  }
`;

export const AdUnitEditButton: FC<Props> = ({ children, adUnitRef }) => {
  const adUnit = useFragment<AdUnitEditButton_adUnit>(
    adUnitFragment,
    adUnitRef
  );

  return (
    <ModalButton
      variant="outlined"
      color="primary"
      render={({ close }) => (
        <AdUnitEditForm adUnitRef={adUnit} onSubmitCompleted={close} />
      )}
    >
      {children}
    </ModalButton>
  );
};
