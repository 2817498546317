import { Button, ButtonProps } from "@material-ui/core";
import { forwardRef } from "react";
import { Link, LinkProps } from "react-router-dom";

type Props = ButtonProps & LinkProps;

const ReferensableLink = forwardRef<Link, LinkProps>((props, ref) => (
  // @ts-expect-error
  <Link {...props} ref={ref} />
));

export const LinkButton = (props: Props) => (
  <Button component={ReferensableLink} {...props} />
);
