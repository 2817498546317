import { ListItem, ListItemProps } from "@material-ui/core";
import { forwardRef } from "react";
import { Link, LinkProps } from "react-router-dom";

type Props = ListItemProps & LinkProps;

const ReferensableLink = forwardRef<Link, LinkProps>((props, ref) => (
  // @ts-expect-error
  <Link {...props} ref={ref} />
));

export const LinkListItem = (props: Props) => (
  // @ts-expect-error
  <ListItem component={ReferensableLink} {...props} />
);
