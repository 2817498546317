import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { FormikTextField, useFormErrorHandler } from "@vrize/vrizead-use";
import { Form, Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import { FC, useCallback } from "react";
import * as yup from "yup";

export type FormValues = {
  email: string;
  password: string;
};

type Props = {
  title: string;
  submitText: string;
  onSubmit: (values: FormValues) => Promise<any>;
};

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },

  cardAction: {
    width: "100%",
  },
  submit: {
    marginTop: theme.spacing(3),
  },
}));

const validationSchema = yup.object().shape({
  email: yup.string().required(),
  password: yup.string().required(),
});

const initialValues = {
  email: "",
  password: "",
};

export const AuthForm: FC<Props> = ({ title, submitText, onSubmit }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();

  const handleSubmit = useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        await onSubmit(values);
        enqueueSnackbar("ログインしました", { variant: "success" });
      } catch (error) {
        formErrorHandler(error, setErrors);
      }
    },
    [enqueueSnackbar, formErrorHandler, onSubmit]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Card className={classes.card}>
            <CardHeader title={title} />
            <CardContent>
              <FormikTextField
                required
                fullWidth
                name="email"
                label="メールアドレス"
                margin="normal"
              />
              <FormikTextField
                required
                fullWidth
                name="password"
                label="パスワード"
                type="password"
                margin="normal"
              />
            </CardContent>
            <CardActions className={classes.cardAction}>
              <Button
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
                disabled={isSubmitting}
                className={classes.submit}
              >
                {submitText}
              </Button>
            </CardActions>
          </Card>
        </Form>
      )}
    </Formik>
  );
};
