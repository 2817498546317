import { Icon } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { FC } from "react";
import { graphql } from "react-relay";
import { useParams } from "react-router";
import { useQuery } from "relay-hooks";

import { AppList_Query } from "~/__relay_artifacts__/AppList_Query.graphql";
import AppListTableCard from "~/components/AppListTableCard";
import { PageHeader } from "~/components/PageHeader";
import { LinkButton } from "~/components/atoms/LinkButton";
import { ITEMS_PER_PAGE } from "~/lib/constants";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
}));

const query = graphql`
  query AppList_Query($projectId: ID!, $first: Int, $after: String) {
    project(id: $projectId) {
      ...AppListTableCard_project
    }
  }
`;

export const AppListContainer: FC = () => {
  const classes = useStyles();
  const { projectId } = useParams<{ projectId: string }>();
  const { props, error } = useQuery<AppList_Query>(
    query,
    { projectId, first: ITEMS_PER_PAGE },
    { networkCacheConfig: { force: true } }
  );

  return (
    <>
      <PageHeader title="アプリ">
        <LinkButton color="inherit" className={classes.button} to="apps/create">
          <Icon className={classes.leftIcon}>add</Icon>作成する
        </LinkButton>
      </PageHeader>
      {(() => {
        if (error) return <div>{error.message}</div>;
        if (!props) return <div>Loading</div>;
        if (!props.project) return <div>not found</div>;
        if (props) return <AppListTableCard project={props.project} />;
      })()}
    </>
  );
};
