/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Platform = "ANDROID" | "IOS" | "WEB" | "%future added value";
export type AuctionSettingEditForm_auctionSetting = {
    readonly id: string;
    readonly platform: Platform;
    readonly floorPrice: number;
    readonly " $refType": "AuctionSettingEditForm_auctionSetting";
};
export type AuctionSettingEditForm_auctionSetting$data = AuctionSettingEditForm_auctionSetting;
export type AuctionSettingEditForm_auctionSetting$key = {
    readonly " $data"?: AuctionSettingEditForm_auctionSetting$data;
    readonly " $fragmentRefs": FragmentRefs<"AuctionSettingEditForm_auctionSetting">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AuctionSettingEditForm_auctionSetting",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "platform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "floorPrice",
      "storageKey": null
    }
  ],
  "type": "AuctionSetting",
  "abstractKey": null
};
(node as any).hash = '953a0fa1c0cbae83cfe7ab76afda37d3';
export default node;
