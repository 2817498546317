import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
} from "@material-ui/core";
import { FormikTextField } from "@vrize/vrizead-use";
import { Form, useFormikContext } from "formik";
import { FC } from "react";
import * as yup from "yup";

export type FormValues = {
  email: string;
  password: string;
};

type Props = {
  title: string;
};

export const schema = yup.object().shape({
  email: yup.string().required(),
  password: yup.string().required(),
});

export const UserForm: FC<Props> = ({ title }) => {
  const { isSubmitting } = useFormikContext<FormValues>();

  return (
    <Form>
      <Card>
        <CardHeader title={title} />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormikTextField
                fullWidth
                name="email"
                label="Email"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                fullWidth
                name="password"
                label="Password"
                type="password"
                margin="normal"
                autoComplete="current-password"
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            fullWidth
            color="primary"
            type="submit"
            variant="contained"
            disabled={isSubmitting}
          >
            保存する
          </Button>
        </CardActions>
      </Card>
    </Form>
  );
};
