import { Button, CardActions } from "@material-ui/core";
import { FormikTextField } from "@vrize/vrizead-use";
import { Form, useFormikContext } from "formik";
import { FC } from "react";

export type FormValues = {
  email: string;
};

export const AccountForm: FC = () => {
  const { isSubmitting } = useFormikContext();

  return (
    <Form>
      <FormikTextField name="email" label="Email" margin="normal" />
      <CardActions className="md-divider-border md-divider-border--top">
        <Button variant="outlined" type="submit" disabled={isSubmitting}>
          保存
        </Button>
      </CardActions>
    </Form>
  );
};
