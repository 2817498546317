import { useCallback } from "react";
import { graphql, useRelayEnvironment } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  UpdateMembershipRoleInput,
  UpdateMembershipRoleMutation,
} from "~/__relay_artifacts__/UpdateMembershipRoleMutation.graphql";

const mutation = graphql`
  mutation UpdateMembershipRoleMutation($input: UpdateMembershipRoleInput!) {
    updateMembershipRole(input: $input) {
      membership {
        id
        role
      }
    }
  }
`;

export const useUpdateMembershipRoleMutation = () => {
  const environment = useRelayEnvironment();

  const updateMembershipRoleMutation = useCallback(
    (input: UpdateMembershipRoleInput) =>
      commitMutation<UpdateMembershipRoleMutation>(environment, {
        mutation,
        variables: { input },
      }),
    [environment]
  );

  return { updateMembershipRoleMutation };
};
