import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import { FC, useCallback, useMemo } from "react";
import { FragmentRef, graphql } from "react-relay";

import { AuctionSettingEditForm_auctionSetting } from "~/__relay_artifacts__/AuctionSettingEditForm_auctionSetting.graphql";
import {
  AuctionSettingForm,
  FormValues,
  schema,
} from "~/components/AuctionSettingForm";
import { extractValidationErrors } from "~/lib/mutationUtils";
import { useFragment } from "~/lib/relay-hooks";
import { useUpdateAuctionSettingMutation } from "~/mutations/UpdateAuctionSettingMutation";

type Props = {
  auctionSettingRef: FragmentRef<AuctionSettingEditForm_auctionSetting>;
  onSubmitCompleted: () => void;
};

const auctionSettingFragment = graphql`
  fragment AuctionSettingEditForm_auctionSetting on AuctionSetting {
    id
    platform
    floorPrice
  }
`;

export const AuctionSettingEditForm: FC<Props> = ({
  auctionSettingRef,
  onSubmitCompleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { updateAuctionSettingMutation } = useUpdateAuctionSettingMutation();
  const auctionSetting = useFragment<AuctionSettingEditForm_auctionSetting>(
    auctionSettingFragment,
    auctionSettingRef
  );

  const initialValues = useMemo<FormValues>(() => {
    const { id, ...rest } = auctionSetting;
    return rest;
  }, [auctionSetting]);

  const onSubmit = useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const { updateAuctionSetting } = await updateAuctionSettingMutation({
          id: auctionSetting.id,
          ...values,
        });
        if (!updateAuctionSetting?.auctionSetting) {
          throw new Error("assertion failed");
        }
        enqueueSnackbar("オークション設定を更新しました", {
          variant: "success",
        });
        onSubmitCompleted();
      } catch (err) {
        const errors = extractValidationErrors<FormValues>(err);
        if (errors) setErrors(errors);
      }
    },
    [
      updateAuctionSettingMutation,
      auctionSetting.id,
      enqueueSnackbar,
      onSubmitCompleted,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      <AuctionSettingForm />
    </Formik>
  );
};
