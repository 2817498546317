import { Icon } from "@material-ui/core";
import { FC } from "react";

import { ModalButton } from "~/components/ModalButton";
import { MembershipCreateForm } from "~/containers/MembershipCreateForm";

export const MembershipCreateButton: FC = () => (
  <ModalButton
    render={({ close }) => <MembershipCreateForm onSubmitCompleted={close} />}
  >
    <Icon>add</Icon> 新規作成
  </ModalButton>
);
