import { FC } from "react";
import { graphql } from "react-relay";
import { Redirect } from "react-router";
import { useQuery } from "relay-hooks";

import { AdminRoot_Query } from "~/__relay_artifacts__/AdminRoot_Query.graphql";

const query = graphql`
  query AdminRoot_Query {
    viewer {
      role
    }
  }
`;

const useRole = () => {
  const { props } = useQuery<AdminRoot_Query>(query, {});
  return {
    loading: !props,
    role: props?.viewer?.role,
  };
};

export const AdminRoot: FC = ({ children }) => {
  const { loading, role } = useRole();
  if (loading) return <>Loading...</>;
  if (!role || role !== "ADMIN") return <Redirect to="/" />;
  return <>{children}</>;
};
