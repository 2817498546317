import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import { FC, useCallback } from "react";

import {
  AuctionSettingForm,
  FormValues,
  schema,
} from "~/components/AuctionSettingForm";
import { extractValidationErrors } from "~/lib/mutationUtils";
import { useCreateAuctionSettingMutation } from "~/mutations/CreateAuctionSettingMutation";

type Props = {
  adUnitId: string;
  onSubmitCompleted: () => void;
};

const initialValues: FormValues = {
  platform: "IOS",
  floorPrice: 0,
};

export const AuctionSettingCreateForm: FC<Props> = ({
  adUnitId,
  onSubmitCompleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { createAuctionSettingMutation } = useCreateAuctionSettingMutation();

  const onSubmit = useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const { createAuctionSetting } = await createAuctionSettingMutation({
          adUnitId: adUnitId,
          ...values,
        });
        if (!createAuctionSetting?.auctionSettingEdge) {
          throw new Error("assertion failed");
        }
        enqueueSnackbar("スケジュールを作成しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
        const errors = extractValidationErrors<FormValues>(err);
        if (errors) setErrors(errors);
      }
    },
    [createAuctionSettingMutation, adUnitId, enqueueSnackbar, onSubmitCompleted]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      <AuctionSettingForm />
    </Formik>
  );
};
