import { useCallback } from "react";
import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { useRelayEnvironment } from "relay-hooks";

import {
  DeleteDailyAuctionSettingInput,
  DeleteDailyAuctionSettingMutation,
} from "~/__relay_artifacts__/DeleteDailyAuctionSettingMutation.graphql";

type Options = {
  auctionSettingId: string;
};

const mutation = graphql`
  mutation DeleteDailyAuctionSettingMutation(
    $input: DeleteDailyAuctionSettingInput!
  ) {
    deleteDailyAuctionSetting(input: $input) {
      deletedDailyAuctionSettingId
    }
  }
`;

export const useDeleteDailyAuctionSettingMutation = ({
  auctionSettingId,
}: Options) => {
  const environment = useRelayEnvironment();

  const deleteDailyAuctionSettingMutation = useCallback(
    (input: DeleteDailyAuctionSettingInput) =>
      commitMutation<DeleteDailyAuctionSettingMutation>(environment, {
        mutation,
        variables: { input },
        configs: [
          {
            type: "RANGE_DELETE",
            parentID: auctionSettingId,
            connectionKeys: [
              {
                key: "DailyAuctionSettingListTableCard_dailyAuctionSettings",
              },
            ],
            pathToConnection: ["auctionSetting", "dailyAuctionSettings"],
            deletedIDFieldName: "deletedDailyAuctionSettingId",
          },
        ],
      }),
    [auctionSettingId, environment]
  );

  return { deleteDailyAuctionSettingMutation };
};
