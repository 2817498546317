import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import * as DateFns from "date-fns";
import { FC, useMemo } from "react";
import { createPaginationContainer, graphql } from "react-relay";

import { MembershipList_project } from "~/__relay_artifacts__/MembershipList_project.graphql";
import { MembershipList_viewer } from "~/__relay_artifacts__/MembershipList_viewer.graphql";
import { MembershipCreateButton } from "~/containers/MembershipCreateButton";
import { MembershipDeleteButton } from "~/containers/MembershipDeleteButton";
import { MembershipRoleEditButton } from "~/containers/MembershipRoleEditButton";

type Props = {
  project: MembershipList_project;
  viewer: MembershipList_viewer;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
  },
  table: {
    minWidth: 700,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  spacer: {
    flex: "1 1 100%",
  },
  button: {
    whiteSpace: "nowrap",
    margin: theme.spacing(1),
  },
  title: {
    flex: "0 0 auto",
  },
  actions: {
    display: "flex",
  },
}));

export const MembershipList: FC<Props> = ({ project, viewer }) => {
  const classes = useStyles();

  const memberships = useMemo(() => {
    const edges = project.memberships?.edges || [];
    return edges
      .map((edge) => {
        if (!edge?.node) throw new Error("assertion failed");
        return {
          ...edge.node,
          editable:
            project.viewerCanAdministrate && edge.node.user.id !== viewer.id,
        };
      })
      .filter((node) =>
        // NOTE: 閲覧者がADMINの権限を持っていない場合は他のADMINユーザを表示しない
        viewer.role !== "ADMIN" && node.role === "ADMIN" ? false : true
      );
  }, [
    project.memberships,
    project.viewerCanAdministrate,
    viewer.id,
    viewer.role,
  ]);

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <div className={classes.title}>
          <Typography variant="subtitle1" color="inherit">
            メンバー一覧
          </Typography>
        </div>
        <div className={classes.actions}>
          {project.viewerCanAdministrate && <MembershipCreateButton />}
        </div>
      </Toolbar>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>役割</TableCell>
              <TableCell>作成日</TableCell>
              <TableCell>アクション</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {memberships.map((membership) => (
              <TableRow key={membership.id} hover>
                <TableCell>{membership.user.email}</TableCell>
                <TableCell>
                  {membership.editable ? (
                    <MembershipRoleEditButton membershipRef={membership}>
                      {membership.role}
                    </MembershipRoleEditButton>
                  ) : (
                    membership.role
                  )}
                </TableCell>
                <TableCell>
                  {DateFns.format(
                    DateFns.parseISO(membership.createdAt),
                    "MM/dd/yyyy"
                  )}
                </TableCell>
                <TableCell>
                  {membership.editable && (
                    <MembershipDeleteButton
                      membershipId={membership.id}
                      projectId={project.id}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
};

export default createPaginationContainer(
  MembershipList,
  {
    project: graphql`
      fragment MembershipList_project on Project {
        id
        viewerCanAdministrate
        memberships(first: $first, after: $after)
          @connection(key: "MembershipList_memberships", filters: []) {
          totalCount
          edges {
            node {
              id
              role
              createdAt
              user {
                id
                email
              }
              ...MembershipRoleEditButton_membership
            }
          }
        }
      }
    `,
    viewer: graphql`
      fragment MembershipList_viewer on User {
        id
        email
        role
      }
    `,
  },
  {
    getConnectionFromProps(props) {
      return props.project.memberships as any;
    },
    getVariables({ project }, { count, cursor }) {
      return {
        projectId: project.id,
        first: count,
        after: cursor,
      };
    },
    query: graphql`
      query MembershipList_Query($projectId: ID!, $first: Int, $after: String) {
        viewer {
          ...MembershipList_viewer
        }
        project(id: $projectId) {
          ...MembershipList_project
        }
      }
    `,
  }
);
