import { FC } from "react";
import { graphql } from "react-relay";
import { useParams } from "react-router";
import { useQuery } from "relay-hooks";

import { AppCreate_Query } from "~/__relay_artifacts__/AppCreate_Query.graphql";
import { PageHeader } from "~/components/PageHeader";
import { AppCreateForm } from "~/containers/AppCreateForm";

const query = graphql`
  query AppCreate_Query($projectId: ID!) {
    project(id: $projectId) {
      id
    }
  }
`;

export const AppCreateContainer: FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { props } = useQuery<AppCreate_Query>(
    query,
    { projectId },
    { networkCacheConfig: { force: true } }
  );

  return (
    <>
      <PageHeader title="アプリの作成" />
      {props?.project?.id && <AppCreateForm />}
    </>
  );
};
