import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { FormikSelectField, FormikTextField } from "@vrize/vrizead-use";
import { Form, useFormikContext } from "formik";
import { FC } from "react";
import * as yup from "yup";

export type FormValues = {
  name: string;
  url: string;
  platform: "ANDROID" | "IOS" | "WEB" | "%future added value";
  frequencyCapEnabled: boolean;
  frequencyCapAmount?: number;
  frequencyCapInterval?:
    | "DAY"
    | "FIFTEEN_MINUTES"
    | "FIVE_MINUTES"
    | "HOUR"
    | "MINUTE"
    | "MONTH"
    | "NONE"
    | "SIX_HOURS"
    | "TWELVE_HOURS"
    | "TWO_HOURS"
    | "WEEK"
    | "YEAR";
  geoLocationEnabled: boolean;
};

export const validationSchema = yup.object().shape({
  name: yup.string().required(),
  url: yup.string().required(),
  platform: yup.string().oneOf(["IOS", "ANDROID", "WEB"]).required(),
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
  },
}));

const platformOptions = [{ value: "WEB", label: "WEB" }];

export const AppForm: FC = () => {
  const classes = useStyles();
  const { isSubmitting } = useFormikContext();

  return (
    <Form className={classes.root}>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormikTextField
                required
                fullWidth
                name="name"
                label="アプリ名"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                required
                fullWidth
                name="url"
                label="URL"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <FormikSelectField
                required
                fullWidth
                name="platform"
                label="プラットフォーム"
                initialValue={platformOptions[0].value}
                options={platformOptions}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            fullWidth
            type="submit"
            color="primary"
            variant="contained"
            disabled={isSubmitting}
          >
            保存する
          </Button>
        </CardActions>
      </Card>
    </Form>
  );
};
