import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import { FC, useCallback, useMemo } from "react";
import { FragmentRef, graphql } from "react-relay";
import { useHistory, useParams } from "react-router";

import { AppEditForm_app } from "~/__relay_artifacts__/AppEditForm_app.graphql";
import { AppForm, FormValues, validationSchema } from "~/components/AppForm";
import { extractValidationErrors } from "~/lib/mutationUtils";
import { useFragment } from "~/lib/relay-hooks";
import { useUpdateAppMutation } from "~/mutations/UpdateAppMutation";

type Props = {
  appRef: FragmentRef<AppEditForm_app>;
};

const appFragment = graphql`
  fragment AppEditForm_app on App {
    id
    name
    platform
    url
    geoLocationEnabled
    frequencyCapEnabled
  }
`;

export const AppEditForm: FC<Props> = ({ appRef }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { projectId } = useParams<{ projectId: string }>();
  const { updateAppMutation } = useUpdateAppMutation();
  const app = useFragment<AppEditForm_app>(appFragment, appRef);

  const initialValues = useMemo(() => app, [app]);

  const onSubmit = useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const { updateApp } = await updateAppMutation({
          id: app.id,
          ...values,
        });
        if (!updateApp?.app) throw new Error("assertion failed");
        enqueueSnackbar("アプリを更新しました", { variant: "success" });
        history.push(`/projects/${projectId}/apps/${app.id}`);
      } catch (err) {
        const errors = extractValidationErrors<FormValues>(err);
        if (errors) setErrors(errors);
      }
    },
    [updateAppMutation, app.id, enqueueSnackbar, history, projectId]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      <AppForm />
    </Formik>
  );
};
