import { useSnackbar } from "notistack";
import { FC, useCallback } from "react";

import { ConfirmButton } from "~/components/atoms/ConfirmButton";
import { useDeleteUserMutation } from "~/mutations/DeleteUserMutation";

type Props = {
  userId: string;
};

export const UserDeleteButton: FC<Props> = ({ userId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { deleteUserMutation } = useDeleteUserMutation();

  const handleDelete = useCallback(async () => {
    try {
      const { deleteUser } = await deleteUserMutation({ id: userId });
      if (!deleteUser?.deletedUserId) throw new Error("assertion failed");
      enqueueSnackbar("ユーザーを削除しました。", { variant: "success" });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }, [deleteUserMutation, enqueueSnackbar, userId]);

  return (
    <ConfirmButton
      onAgree={async (changeModal) => {
        await handleDelete();
        changeModal(false);
      }}
      variant="outlined"
      color="inherit"
      confirmTitle="このユーザーを削除しますか？"
      confirmContent=""
    >
      削除
    </ConfirmButton>
  );
};
