import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import { FC, useCallback } from "react";

import { FormValues, UserForm, schema } from "~/components/UserForm";
import { extractValidationErrors } from "~/lib/mutationUtils";
import { useCreateUserMutation } from "~/mutations/CreateUserMutation";

type Props = {
  title: string;
  onSubmitCompleted: () => void;
};

const initialValues = {
  email: "",
  password: "",
};

export const UserCreateForm: FC<Props> = ({ title, onSubmitCompleted }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { createUserMutation } = useCreateUserMutation();

  const onSubmit = useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const { createUser } = await createUserMutation(values);
        if (!createUser?.userEdge) throw new Error("assertion failed");
        enqueueSnackbar("ユーザーを作成しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
        const errors = extractValidationErrors<FormValues>(err);
        if (errors) setErrors(errors);
      }
    },
    [createUserMutation, enqueueSnackbar, onSubmitCompleted]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      <UserForm title={title} />
    </Formik>
  );
};
