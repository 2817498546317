import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as R from "ramda";
import { FC, useCallback, useMemo } from "react";

import { Platform } from "~/__relay_artifacts__/CreateAuctionSettingMutation.graphql";
import {
  DailyAuctionSettingForm,
  FormValues,
  schema,
} from "~/components/DailyAuctionSettingForm";
import { useCreateDailyAuctionSettingMutation } from "~/mutations/CreateDailyAuctionSettingMutation";

type Props = {
  auctionSettingId: string;
  platform: Platform;
  onSubmitCompleted: () => void;
};

export const DailyAuctionSettingCreateForm: FC<Props> = ({
  auctionSettingId,
  platform,
  onSubmitCompleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { createDailyAuctionSettingMutation } =
    useCreateDailyAuctionSettingMutation();

  const initialValues = useMemo(
    () => ({
      floorPrice: 0,
      platform: platform,
      date: new Date().toISOString(),
    }),
    [platform]
  );

  const onSubmit = useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      const { date, floorPrice, platform } = values;
      if (R.isNil(floorPrice) || R.isNil(date)) {
        throw new Error("assertion failed");
      }
      const input = {
        auctionSettingId: auctionSettingId,
        floorPrice: floorPrice,
        platform: platform,
        date: date,
      };

      try {
        const { createDailyAuctionSetting } =
          await createDailyAuctionSettingMutation(input);
        if (!createDailyAuctionSetting?.dailyAuctionSettingEdge) {
          throw new Error("assertion failed");
        }
        enqueueSnackbar("CPMスケジュールを追加しました", {
          variant: "success",
        });
        onSubmitCompleted();
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
        setErrors(err);
      }
    },
    [
      enqueueSnackbar,
      onSubmitCompleted,
      auctionSettingId,
      createDailyAuctionSettingMutation,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      <DailyAuctionSettingForm />
    </Formik>
  );
};
