import { AppBar, Drawer, Hidden, Toolbar } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { FC, useCallback, useState } from "react";
import { createFragmentContainer, graphql } from "react-relay";

import logo from "~/3dad_logo.png";
import { ProjectHeader_project } from "~/__relay_artifacts__/ProjectHeader_project.graphql";
import { ProjectHeader_viewer } from "~/__relay_artifacts__/ProjectHeader_viewer.graphql";

import ProjectDrawerMenuList from "./ProjectDrawerMenuList";
import ProjectSelectionButton from "./ProjectSelectionButton";

type Props = {
  project: ProjectHeader_project;
  viewer: ProjectHeader_viewer;
};

const DRAWER_WIDTH = 240;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    zIndex: theme.zIndex.appBar - 1,
    width: DRAWER_WIDTH,
  },
  appBar: {},
  logo: {
    width: 124,
  },
  logoWrapper: {
    width: DRAWER_WIDTH,
  },
  grow: {
    flexGrow: 1,
  },
  toolbar: theme.mixins.toolbar,
}));

const useMobileOpen = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(!mobileOpen);
  }, [mobileOpen]);

  return { mobileOpen, handleDrawerToggle };
};

const ProjectHeader: FC<Props> = ({ project, viewer }) => {
  const classes = useStyles();
  const { mobileOpen, handleDrawerToggle } = useMobileOpen();

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <div className={classes.logoWrapper}>
            <img src={logo} alt="logo" className={classes.logo} />
          </div>
          <div className={classes.grow}>
            <ProjectSelectionButton
              key="project"
              project={project}
              viewer={viewer}
            />
          </div>
        </Toolbar>
      </AppBar>
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.toolbar} />
          <ProjectDrawerMenuList project={project} viewer={viewer} />
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          open
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
        >
          <div className={classes.toolbar} />
          <ProjectDrawerMenuList project={project} viewer={viewer} />
        </Drawer>
      </Hidden>
    </div>
  );
};

export default createFragmentContainer(ProjectHeader, {
  viewer: graphql`
    fragment ProjectHeader_viewer on User {
      id
      ...AccountButton_viewer
      ...ProjectSelectionButton_viewer
      ...ProjectDrawerMenuList_viewer
    }
  `,
  project: graphql`
    fragment ProjectHeader_project on Project {
      ...ProjectSelectionButton_project
      ...ProjectDrawerMenuList_project
    }
  `,
});
