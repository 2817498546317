/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdPosition = "ABOVE_THE_FOLD" | "BELOW_THE_FOLD" | "DEPRECATED" | "FOOTER" | "FULL_SCREEN" | "HEADER" | "SIDEBAR" | "UNKNOWN" | "%future added value";
export type AdUnitEditForm_adUnit = {
    readonly id: string;
    readonly name: string;
    readonly width: number;
    readonly height: number;
    readonly adPosition: AdPosition;
    readonly frequencyCapEnabled: boolean;
    readonly tagId: string;
    readonly passbackIosTag: string;
    readonly passbackAndroidTag: string;
    readonly " $refType": "AdUnitEditForm_adUnit";
};
export type AdUnitEditForm_adUnit$data = AdUnitEditForm_adUnit;
export type AdUnitEditForm_adUnit$key = {
    readonly " $data"?: AdUnitEditForm_adUnit$data;
    readonly " $fragmentRefs": FragmentRefs<"AdUnitEditForm_adUnit">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdUnitEditForm_adUnit",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "width",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "height",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adPosition",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "frequencyCapEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tagId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "passbackIosTag",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "passbackAndroidTag",
      "storageKey": null
    }
  ],
  "type": "AdUnit",
  "abstractKey": null
};
(node as any).hash = '173c4aeda100c6800ccfb455b8c5bc3c';
export default node;
