/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AppDetail_app = {
    readonly name: string;
    readonly " $fragmentRefs": FragmentRefs<"AppDetailCard_app" | "AdUnitListTableCard_app">;
    readonly " $refType": "AppDetail_app";
};
export type AppDetail_app$data = AppDetail_app;
export type AppDetail_app$key = {
    readonly " $data"?: AppDetail_app$data;
    readonly " $fragmentRefs": FragmentRefs<"AppDetail_app">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppDetail_app",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AppDetailCard_app"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AdUnitListTableCard_app"
    }
  ],
  "type": "App",
  "abstractKey": null
};
(node as any).hash = '27524e6e9ea7bd66207b90b267593a92';
export default node;
