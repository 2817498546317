import { Icon } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { FC } from "react";

import { Home_QueryResponse } from "~/__relay_artifacts__/Home_Query.graphql";
import { PageHeader } from "~/components/PageHeader";
import { LinkButton } from "~/components/atoms/LinkButton";

import MembershipList from "./MembershipList";

type Props = {
  project: NonNullable<Home_QueryResponse["project"]>;
  viewer: Home_QueryResponse["viewer"];
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
}));

export const PageContent: FC<Props> = ({ project, viewer }) => {
  const classes = useStyles();
  return (
    <>
      <PageHeader title="ホーム">
        <LinkButton
          color="inherit"
          className={classes.button}
          to={`/projects/${project.id}/edit`}
        >
          <Icon className={classes.leftIcon}>edit</Icon>
          <span>プロジェクトを編集する</span>
        </LinkButton>
      </PageHeader>
      <MembershipList project={project} viewer={viewer} />
    </>
  );
};
