import { useCallback } from "react";
import { graphql, useRelayEnvironment } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  UpdateAppInput,
  UpdateAppMutation,
} from "~/__relay_artifacts__/UpdateAppMutation.graphql";

const mutation = graphql`
  mutation UpdateAppMutation($input: UpdateAppInput!) {
    updateApp(input: $input) {
      app {
        id
      }
    }
  }
`;

export const useUpdateAppMutation = () => {
  const environment = useRelayEnvironment();

  const updateAppMutation = useCallback(
    (input: UpdateAppInput) =>
      commitMutation<UpdateAppMutation>(environment, {
        mutation,
        variables: { input },
      }),
    [environment]
  );

  return { updateAppMutation };
};
