import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
} from "@material-ui/core";
import { FormikNumberField, FormikSelectField } from "@vrize/vrizead-use";
import { Form, useFormikContext } from "formik";
import { FC } from "react";
import * as yup from "yup";

import { Platform } from "~/__relay_artifacts__/CreateAuctionSettingMutation.graphql";

export type FormValues = {
  platform: Platform;
  floorPrice: number;
};

export const schema = yup.object().shape({
  platform: yup.string().oneOf(["IOS", "ANDROID"]).required(),
  floorPrice: yup.number().required(),
});

const platformOptions = [
  { value: "IOS", label: "iOS" },
  { value: "ANDROID", label: "Android" },
];

export const AuctionSettingForm: FC = () => {
  const { isSubmitting } = useFormikContext();

  return (
    <Form>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormikSelectField
                name="platform"
                label="プラットフォーム"
                fullWidth
                options={platformOptions}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikNumberField
                fullWidth
                name="floorPrice"
                label="最低落札価格"
                margin="normal"
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className="md-divider-border md-divider-border--top">
          <Button
            type="submit"
            color="primary"
            fullWidth
            variant="contained"
            disabled={isSubmitting}
          >
            保存する
          </Button>
        </CardActions>
      </Card>
    </Form>
  );
};
