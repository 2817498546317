import { Button, Grid } from "@material-ui/core";
import { FormikTextField } from "@vrize/vrizead-use";
import { Form, useFormikContext } from "formik";
import { FC } from "react";
import * as yup from "yup";

export type FormValues = {
  name: string;
};

export const schema = yup.object().shape({
  name: yup.string().required(),
});

export const ProjectForm: FC = () => {
  const { isSubmitting } = useFormikContext<FormValues>();

  return (
    <Form>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormikTextField fullWidth name="name" label="名前" />
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            type="submit"
            color="primary"
            variant="contained"
            disabled={isSubmitting}
          >
            保存する
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};
