import {
  AppBar,
  Breadcrumbs,
  IconButton,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { ArrowBack as MenuIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { FC } from "react";
import { Link } from "react-router-dom";

type BreadcrumbItem = {
  title: string;
  link: string;
};

type Props = {
  title?: string;
  tabs?: any;
  backTo?: string;
  breadcrumbs?: BreadcrumbItem[];
};

const useStyles = makeStyles({
  breadcrumbs: {
    minHeight: 40,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
});

const RouteBreadcrumbs: React.FC<{ items: BreadcrumbItem[] }> = ({ items }) => {
  const lastItem = items.pop();
  if (!lastItem) throw new Error("assertion failed");
  return (
    <Breadcrumbs aria-label="Breadcrumb">
      {items.map((item) => (
        <Link key={item.link} color="inherit" to={item.link}>
          {item.title}
        </Link>
      ))}
      <Typography color="textPrimary">{lastItem.title}</Typography>
    </Breadcrumbs>
  );
};

export const PageHeader: FC<Props> = ({
  children,
  title,
  tabs,
  backTo,
  breadcrumbs,
}) => {
  const classes = useStyles();

  return (
    <>
      <AppBar position="static" elevation={1} color="default">
        <Toolbar>
          {backTo && (
            <Link to={backTo}>
              <IconButton className={classes.menuButton} aria-label="Menu">
                <MenuIcon />
              </IconButton>
            </Link>
          )}
          <Typography variant="h6" color="inherit">
            {title}
          </Typography>
          {children}
        </Toolbar>
        {tabs}
      </AppBar>
      {breadcrumbs && (
        <Toolbar className={classes.breadcrumbs}>
          <RouteBreadcrumbs items={breadcrumbs} />
        </Toolbar>
      )}
    </>
  );
};
