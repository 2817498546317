/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdPosition = "ABOVE_THE_FOLD" | "BELOW_THE_FOLD" | "DEPRECATED" | "FOOTER" | "FULL_SCREEN" | "HEADER" | "SIDEBAR" | "UNKNOWN" | "%future added value";
export type FrequencyCapInterval = "DAY" | "FIFTEEN_MINUTES" | "FIVE_MINUTES" | "HOUR" | "MINUTE" | "MONTH" | "NONE" | "SIX_HOURS" | "TWELVE_HOURS" | "TWO_HOURS" | "WEEK" | "YEAR" | "%future added value";
export type CreateAdUnitInput = {
    clientMutationId?: string | null;
    appId: string;
    name: string;
    width: number;
    height: number;
    adPosition: AdPosition;
    frequencyCapEnabled?: boolean | null;
    frequencyCap?: FrequencyCapInput | null;
    tagId?: string | null;
    passbackIosTag?: string | null;
    passbackAndroidTag?: string | null;
    passbackValidationEnabled: boolean;
};
export type FrequencyCapInput = {
    amount: number;
    interval: FrequencyCapInterval;
};
export type CreateAdUnitMutationVariables = {
    input: CreateAdUnitInput;
};
export type CreateAdUnitMutationResponse = {
    readonly createAdUnit: {
        readonly adUnitEdge: {
            readonly node: {
                readonly id: string;
                readonly name: string;
                readonly createdAt: string;
                readonly updatedAt: string;
                readonly width: number;
                readonly height: number;
                readonly adPosition: AdPosition;
                readonly tagId: string;
                readonly embedTag: string;
                readonly frequencyCapEnabled: boolean;
                readonly " $fragmentRefs": FragmentRefs<"AdUnitEditButton_adUnit" | "AdUnitTagCard_adUnit" | "AuctionSettingList_adUnit">;
            } | null;
        } | null;
    } | null;
};
export type CreateAdUnitMutation = {
    readonly response: CreateAdUnitMutationResponse;
    readonly variables: CreateAdUnitMutationVariables;
};



/*
mutation CreateAdUnitMutation(
  $input: CreateAdUnitInput!
) {
  createAdUnit(input: $input) {
    adUnitEdge {
      node {
        id
        name
        createdAt
        updatedAt
        width
        height
        adPosition
        tagId
        embedTag
        frequencyCapEnabled
        ...AdUnitEditButton_adUnit
        ...AdUnitTagCard_adUnit
        ...AuctionSettingList_adUnit
      }
    }
  }
}

fragment AdUnitEditButton_adUnit on AdUnit {
  ...AdUnitEditForm_adUnit
}

fragment AdUnitEditForm_adUnit on AdUnit {
  id
  name
  width
  height
  adPosition
  frequencyCapEnabled
  tagId
  passbackIosTag
  passbackAndroidTag
}

fragment AdUnitTagCard_adUnit on AdUnit {
  id
  embedTag
}

fragment AuctionSettingEditButton_auctionSetting on AuctionSetting {
  ...AuctionSettingEditForm_auctionSetting
}

fragment AuctionSettingEditForm_auctionSetting on AuctionSetting {
  id
  platform
  floorPrice
}

fragment AuctionSettingList_adUnit on AdUnit {
  id
  auctionSettings(first: 100) {
    edges {
      node {
        id
        platform
        floorPrice
        ...AuctionSettingEditButton_auctionSetting
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "width",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "height",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "adPosition",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tagId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "embedTag",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "frequencyCapEnabled",
  "storageKey": null
},
v12 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateAdUnitMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateAdUnitPayload",
        "kind": "LinkedField",
        "name": "createAdUnit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdUnitEdge",
            "kind": "LinkedField",
            "name": "adUnitEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdUnit",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "AdUnitEditButton_adUnit"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "AdUnitTagCard_adUnit"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "AuctionSettingList_adUnit"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateAdUnitMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateAdUnitPayload",
        "kind": "LinkedField",
        "name": "createAdUnit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdUnitEdge",
            "kind": "LinkedField",
            "name": "adUnitEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdUnit",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "passbackIosTag",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "passbackAndroidTag",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v12/*: any*/),
                    "concreteType": "AuctionSettingConnection",
                    "kind": "LinkedField",
                    "name": "auctionSettings",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AuctionSettingEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AuctionSetting",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "platform",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "floorPrice",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "auctionSettings(first:100)"
                  },
                  {
                    "alias": null,
                    "args": (v12/*: any*/),
                    "filters": null,
                    "handle": "connection",
                    "key": "AuctionSettingList_auctionSettings",
                    "kind": "LinkedHandle",
                    "name": "auctionSettings"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0a897c0bb4b4277d02d93998ce3c1b9c",
    "id": null,
    "metadata": {},
    "name": "CreateAdUnitMutation",
    "operationKind": "mutation",
    "text": "mutation CreateAdUnitMutation(\n  $input: CreateAdUnitInput!\n) {\n  createAdUnit(input: $input) {\n    adUnitEdge {\n      node {\n        id\n        name\n        createdAt\n        updatedAt\n        width\n        height\n        adPosition\n        tagId\n        embedTag\n        frequencyCapEnabled\n        ...AdUnitEditButton_adUnit\n        ...AdUnitTagCard_adUnit\n        ...AuctionSettingList_adUnit\n      }\n    }\n  }\n}\n\nfragment AdUnitEditButton_adUnit on AdUnit {\n  ...AdUnitEditForm_adUnit\n}\n\nfragment AdUnitEditForm_adUnit on AdUnit {\n  id\n  name\n  width\n  height\n  adPosition\n  frequencyCapEnabled\n  tagId\n  passbackIosTag\n  passbackAndroidTag\n}\n\nfragment AdUnitTagCard_adUnit on AdUnit {\n  id\n  embedTag\n}\n\nfragment AuctionSettingEditButton_auctionSetting on AuctionSetting {\n  ...AuctionSettingEditForm_auctionSetting\n}\n\nfragment AuctionSettingEditForm_auctionSetting on AuctionSetting {\n  id\n  platform\n  floorPrice\n}\n\nfragment AuctionSettingList_adUnit on AdUnit {\n  id\n  auctionSettings(first: 100) {\n    edges {\n      node {\n        id\n        platform\n        floorPrice\n        ...AuctionSettingEditButton_auctionSetting\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'ac9cf4f675ce33215dd54ac341eaa197';
export default node;
