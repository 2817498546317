/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DimensionValue = "DATE" | "GENRE" | "OS" | "%future added value";
export type MeasureFormula = "AVG" | "INT_SUM" | "SUM" | "%future added value";
export type MeasureValue = "CLICK" | "CTR" | "CVR" | "DATE" | "IMPRESSION" | "INSTALL" | "NET_BUDGET_CONSUMPTION" | "NET_CPC" | "NET_CPI" | "OS" | "%future added value";
export type Os = "ANDROID" | "IOS" | "UNKNOWN" | "%future added value";
export type MeasureInput = {
    value: MeasureValue;
    formula?: MeasureFormula | null;
};
export type DimensionInput = {
    value: DimensionValue;
};
export type FilterInput = {
    periodSince: string;
    periodTill: string;
    appId?: string | null;
    adUnitIds?: Array<string> | null;
    os?: Array<Os> | null;
};
export type OrderInput = {
    value: MeasureValue;
    isDesc?: boolean | null;
};
export type PublisherReport_QueryVariables = {
    projectId: string;
    measures: Array<MeasureInput>;
    dimensions: Array<DimensionInput>;
    filter: FilterInput;
    orders?: Array<OrderInput> | null;
};
export type PublisherReport_QueryResponse = {
    readonly project: {
        readonly " $fragmentRefs": FragmentRefs<"AdUnitReportFormContainer_project">;
    } | null;
};
export type PublisherReport_Query = {
    readonly response: PublisherReport_QueryResponse;
    readonly variables: PublisherReport_QueryVariables;
};



/*
query PublisherReport_Query(
  $projectId: ID!
  $measures: [MeasureInput!]!
  $dimensions: [DimensionInput!]!
  $filter: FilterInput!
  $orders: [OrderInput!]
) {
  project(id: $projectId) {
    ...AdUnitReportFormContainer_project
    id
  }
}

fragment AdUnitReportFormContainer_project on Project {
  id
  apps {
    edges {
      node {
        adUnits {
          edges {
            node {
              id
              name
            }
          }
        }
        id
      }
    }
  }
  ...ReportList_project
}

fragment ReportList_project on Project {
  id
  report(measures: $measures, dimensions: $dimensions, filter: $filter, orders: $orders) {
    measures
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dimensions"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "measures"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orders"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PublisherReport_Query",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AdUnitReportFormContainer_project"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "PublisherReport_Query",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AppConnection",
            "kind": "LinkedField",
            "name": "apps",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AppEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "App",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AdUnitConnection",
                        "kind": "LinkedField",
                        "name": "adUnits",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AdUnitEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AdUnit",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "dimensions",
                "variableName": "dimensions"
              },
              {
                "kind": "Variable",
                "name": "filter",
                "variableName": "filter"
              },
              {
                "kind": "Variable",
                "name": "measures",
                "variableName": "measures"
              },
              {
                "kind": "Variable",
                "name": "orders",
                "variableName": "orders"
              }
            ],
            "concreteType": "Report",
            "kind": "LinkedField",
            "name": "report",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "measures",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b15182b83e00a84eba390bbab640ffa4",
    "id": null,
    "metadata": {},
    "name": "PublisherReport_Query",
    "operationKind": "query",
    "text": "query PublisherReport_Query(\n  $projectId: ID!\n  $measures: [MeasureInput!]!\n  $dimensions: [DimensionInput!]!\n  $filter: FilterInput!\n  $orders: [OrderInput!]\n) {\n  project(id: $projectId) {\n    ...AdUnitReportFormContainer_project\n    id\n  }\n}\n\nfragment AdUnitReportFormContainer_project on Project {\n  id\n  apps {\n    edges {\n      node {\n        adUnits {\n          edges {\n            node {\n              id\n              name\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n  ...ReportList_project\n}\n\nfragment ReportList_project on Project {\n  id\n  report(measures: $measures, dimensions: $dimensions, filter: $filter, orders: $orders) {\n    measures\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b16e7e7a672999f974811d2dd9ff7174';
export default node;
