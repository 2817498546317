import "@firebase/auth";

import firebase from "@firebase/app";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { FC } from "react";
import { Link } from "react-router-dom";

import { AuthForm, FormValues } from "~/components/AuthForm";

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: "auto",
    display: "block",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  link: {
    display: "block",
    textAlign: "center",
    marginTop: theme.spacing(3),
  },
}));

const handleSubmit = (values: FormValues) => {
  if (!firebase.auth) throw new Error("firebase.auth is not defined");
  return firebase
    .auth()
    .signInWithEmailAndPassword(values.email, values.password);
};

export const SigninContainer: FC = () => {
  const classes = useStyles();
  return (
    <main className={classes.main}>
      <AuthForm
        title="ログイン"
        submitText="ログインする"
        onSubmit={handleSubmit}
      />
      <Link className={classes.link} to={`/signup`}>
        アカウントを作成する
      </Link>
    </main>
  );
};
