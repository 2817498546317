import { FC } from "react";
import { graphql } from "react-relay";
import { useParams } from "react-router";
import { useQuery } from "relay-hooks";

import { AppDetail_Query } from "~/__relay_artifacts__/AppDetail_Query.graphql";
import AppDetail from "~/components/AppDetail";

const query = graphql`
  query AppDetail_Query(
    $projectId: ID!
    $appId: ID!
    $first: Int
    $after: String
    $orderBy: AdUnitOrder
  ) {
    project(id: $projectId) {
      app(id: $appId) {
        ...AppDetail_app
      }
    }
  }
`;

export const AppDetailContainer: FC = () => {
  const { id, projectId } = useParams<{ id: string; projectId: string }>();
  const { props, error } = useQuery<AppDetail_Query>(
    query,
    {
      projectId,
      appId: id,
      first: 100,
      orderBy: { field: "CREATED_AT", direction: "DESC" },
    },
    { networkCacheConfig: { force: true } }
  );

  if (error) return <div>{error.message}</div>;
  if (!props) return <div>Loading...</div>;
  if (!props.project || !props.project.app) return <div>not found</div>;
  return <AppDetail app={props.project.app} />;
};
