/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UserRole = "ADMIN" | "NORMAL" | "%future added value";
export type AdminRoot_QueryVariables = {};
export type AdminRoot_QueryResponse = {
    readonly viewer: {
        readonly role: UserRole;
    };
};
export type AdminRoot_Query = {
    readonly response: AdminRoot_QueryResponse;
    readonly variables: AdminRoot_QueryVariables;
};



/*
query AdminRoot_Query {
  viewer {
    role
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminRoot_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AdminRoot_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "85fc63d039dfa4bd7924e6d656f06eb1",
    "id": null,
    "metadata": {},
    "name": "AdminRoot_Query",
    "operationKind": "query",
    "text": "query AdminRoot_Query {\n  viewer {\n    role\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '102d1c951d2a7f20aa30c8799c25e0f5';
export default node;
