import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { FC, Fragment } from "react";

type Props = {
  measures: any;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(1),
  },
  card: {
    padding: theme.spacing(1),
    "&:last-child": {
      paddingBottom: theme.spacing(1),
    },
  },
}));

const measureLabel = (field: string) => {
  switch (field) {
    case "IMPRESSION":
      return "IMP";
    case "GROSS_BUDGET_CONSUMPTION":
      return "COST";
    case "NET_BUDGET_CONSUMPTION":
      return "収益";
    case "NET_CPC":
    case "GROSS_CPC":
      return "CPC";
    case "NET_CPI":
    case "GROSS_CPI":
      return "CPI";
    default:
      return field;
  }
};

const sumMeasure = (measures: any) => {
  return measures
    .map((obj: any) => {
      const result: Record<string, any> = {};

      Object.keys(obj).forEach((key: any) => {
        switch (key) {
          case "IMPRESSION":
          case "CLICK":
          case "INSTALL":
          case "CVR":
          case "CTR":
          case "NET_CPC":
          case "NET_CPI":
          case "GROSS_CPC":
          case "GROSS_CPI":
          case "NET_BUDGET_CONSUMPTION":
          case "GROSS_BUDGET_CONSUMPTION":
            result[key] = Number(obj[key]);
            break;
          default:
            result[key] = 0;
        }
      });
      return result;
    })
    .reduce((sum: any, obj: any) => {
      const result: Record<string, any> = {};
      Object.keys(obj).forEach((key) => {
        result[key] = (sum[key] || 0) + obj[key];
      });
      return result;
    }, {});
};

export const ReportAggregate: FC<Props> = ({ measures }) => {
  const classes = useStyles();
  const measure = sumMeasure(measures);
  return (
    <Grid container className={classes.root} spacing={2}>
      {Object.keys(measure).map((key) => {
        let value = measure[key];
        switch (key) {
          case "IMPRESSION":
          case "CLICK":
          case "INSTALL":
            value = parseInt(value || 0, 10).toLocaleString();
            break;
          case "CTR":
            value =
              ((100 * measure.CLICK) / measure.IMPRESSION).toFixed(2) + "%";
            break;
          case "CVR":
            value = ((100 * measure.INSTALL) / measure.CLICK).toFixed(2) + "%";
            break;
          case "NET_CPC":
          case "NET_CPI":
            value =
              "¥ " +
              (measure.NET_BUDGET_CONSUMPTION / measure.INSTALL)
                .toFixed()
                .toLocaleString();
            break;
          case "GROSS_CPC":
          case "GROSS_CPI":
            value =
              "¥ " +
              (measure.GROSS_BUDGET_CONSUMPTION / measure.INSTALL)
                .toFixed()
                .toLocaleString();
            break;
          case "NET_BUDGET_CONSUMPTION":
          case "GROSS_BUDGET_CONSUMPTION":
            value = "¥ " + parseInt(value || 0, 10).toLocaleString();
            break;
          default:
            return <Fragment key={key} />;
        }

        return (
          <Grid key={key} item xs={6} md={2}>
            <Card>
              <CardContent className={classes.card}>
                <Typography component="p">{measureLabel(key)}</Typography>
                <Typography variant="h6">{value}</Typography>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};
