import { useCallback } from "react";
import { graphql, useRelayEnvironment } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  DeleteUserInput,
  DeleteUserMutation,
} from "~/__relay_artifacts__/DeleteUserMutation.graphql";

const mutation = graphql`
  mutation DeleteUserMutation($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      deletedUserId
    }
  }
`;

export const useDeleteUserMutation = () => {
  const environment = useRelayEnvironment();

  const deleteUserMutation = useCallback(
    (input: DeleteUserInput) =>
      commitMutation<DeleteUserMutation>(environment, {
        mutation,
        variables: { input },
        configs: [
          {
            type: "RANGE_DELETE",
            parentID: "client:root",
            connectionKeys: [{ key: "UserListTableCard_users" }],
            pathToConnection: ["root", "users"],
            deletedIDFieldName: "deletedUserId",
          },
        ],
      }),
    [environment]
  );

  return { deleteUserMutation };
};
