import { useCallback } from "react";
import { graphql, useRelayEnvironment } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  DeleteAppInput,
  DeleteAppMutation,
} from "~/__relay_artifacts__/DeleteAppMutation.graphql";

const mutation = graphql`
  mutation DeleteAppMutation($input: DeleteAppInput!) {
    deleteApp(input: $input) {
      deletedAppId
    }
  }
`;

export const useDeleteAppMutation = () => {
  const environment = useRelayEnvironment();

  const deleteAppMutation = useCallback(
    (input: DeleteAppInput) =>
      commitMutation<DeleteAppMutation>(environment, {
        mutation,
        variables: { input },
      }),
    [environment]
  );

  return { deleteAppMutation };
};
