import { useSnackbar } from "notistack";
import { FC, useCallback } from "react";

import { ConfirmButton } from "~/components/atoms/ConfirmButton";
import { useDeleteMembershipMutation } from "~/mutations/DeleteMembershipMutation";

type Props = {
  membershipId: string;
  projectId: string;
};

export const MembershipDeleteButton: FC<Props> = ({
  membershipId,
  projectId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { deleteMembershipMutation } = useDeleteMembershipMutation({
    projectId,
  });

  const handleDelete = useCallback(async () => {
    try {
      const { deleteMembership } = await deleteMembershipMutation({
        id: membershipId,
      });
      if (!deleteMembership?.deletedMembershipId) {
        throw new Error("assertion failed");
      }
      enqueueSnackbar("メンバーを削除しました。", { variant: "success" });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }, [deleteMembershipMutation, enqueueSnackbar, membershipId]);

  return (
    <ConfirmButton
      variant="outlined"
      color="inherit"
      confirmTitle="メンバーを削除しますか？"
      confirmContent=""
      onAgree={async (changeModal) => {
        await handleDelete();
        changeModal(false);
      }}
    >
      削除
    </ConfirmButton>
  );
};
