import { FC } from "react";
import { FragmentRef, graphql } from "react-relay";

import { MembershipRoleEditButton_membership } from "~/__relay_artifacts__/MembershipRoleEditButton_membership.graphql";
import { ModalButton } from "~/components/ModalButton";
import { MembershipRoleEditForm } from "~/containers/MembershipRoleEditForm";
import { useFragment } from "~/lib/relay-hooks";

type Props = {
  membershipRef: FragmentRef<MembershipRoleEditButton_membership>;
};

const membershipFragment = graphql`
  fragment MembershipRoleEditButton_membership on Membership {
    ...MembershipRoleEditForm_membership
  }
`;

export const MembershipRoleEditButton: FC<Props> = ({
  children,
  membershipRef,
}) => {
  const membership = useFragment<MembershipRoleEditButton_membership>(
    membershipFragment,
    membershipRef
  );

  return (
    <ModalButton
      variant="outlined"
      render={({ close }) => (
        <MembershipRoleEditForm
          membershipRef={membership}
          onSubmitCompleted={close}
        />
      )}
    >
      {children}
    </ModalButton>
  );
};
