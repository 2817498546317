import { useCallback } from "react";
import { graphql, useRelayEnvironment } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  UpdateProjectInput,
  UpdateProjectMutation,
} from "~/__relay_artifacts__/UpdateProjectMutation.graphql";

const mutation = graphql`
  mutation UpdateProjectMutation($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      project {
        id
        name
      }
    }
  }
`;

export const useUpdateProjectMutation = () => {
  const environment = useRelayEnvironment();

  const updateProjectMutation = useCallback(
    (input: UpdateProjectInput) =>
      commitMutation<UpdateProjectMutation>(environment, {
        mutation,
        variables: { input },
      }),
    [environment]
  );

  return { updateProjectMutation };
};
