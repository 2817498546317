/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type Platform = "ANDROID" | "IOS" | "WEB" | "%future added value";
export type CreateAuctionSettingInput = {
    clientMutationId?: string | null;
    adUnitId: string;
    platform: Platform;
    floorPrice: number;
};
export type CreateAuctionSettingMutationVariables = {
    input: CreateAuctionSettingInput;
};
export type CreateAuctionSettingMutationResponse = {
    readonly createAuctionSetting: {
        readonly auctionSettingEdge: {
            readonly node: {
                readonly id: string;
                readonly platform: Platform;
                readonly floorPrice: number;
            } | null;
        } | null;
    } | null;
};
export type CreateAuctionSettingMutation = {
    readonly response: CreateAuctionSettingMutationResponse;
    readonly variables: CreateAuctionSettingMutationVariables;
};



/*
mutation CreateAuctionSettingMutation(
  $input: CreateAuctionSettingInput!
) {
  createAuctionSetting(input: $input) {
    auctionSettingEdge {
      node {
        id
        platform
        floorPrice
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateAuctionSettingPayload",
    "kind": "LinkedField",
    "name": "createAuctionSetting",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuctionSettingEdge",
        "kind": "LinkedField",
        "name": "auctionSettingEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuctionSetting",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "platform",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "floorPrice",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateAuctionSettingMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateAuctionSettingMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b5b9d70859fa7b92b850745118c4d102",
    "id": null,
    "metadata": {},
    "name": "CreateAuctionSettingMutation",
    "operationKind": "mutation",
    "text": "mutation CreateAuctionSettingMutation(\n  $input: CreateAuctionSettingInput!\n) {\n  createAuctionSetting(input: $input) {\n    auctionSettingEdge {\n      node {\n        id\n        platform\n        floorPrice\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '95e9ca077af38fafccf277ff574635e4';
export default node;
