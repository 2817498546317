import "@firebase/auth";

import firebase from "@firebase/app";
import { Box, Button, Card, CardContent, CardHeader } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { FC } from "react";
import { graphql } from "react-relay";
import { useAsyncFn } from "react-use";
import { useQuery } from "relay-hooks";

import { Top_Query } from "~/__relay_artifacts__/Top_Query.graphql";
import { ProjectList } from "~/components/ProjectList";
import { LinkButton } from "~/components/atoms/LinkButton";

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: "auto",
    display: "block",
  },
  card: {
    marginTop: theme.spacing(8),
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  cardAction: {
    width: "100%",
  },
  submit: {
    marginTop: theme.spacing(3),
  },
}));

const query = graphql`
  query Top_Query {
    viewer {
      id
      email
      role
      profilePicture
      ...ProjectList_viewer
    }
  }
`;

const Wrapper: FC = ({ children }) => {
  const classes = useStyles();
  return (
    <main className={classes.main}>
      <Card className={classes.card}>
        <CardHeader title="プロジェクトの選択" />
        {children}
      </Card>
    </main>
  );
};

export const TopContainer: FC = () => {
  const { props, error } = useQuery<Top_Query>(query, {});

  const [state, logout] = useAsyncFn(async () => {
    if (!firebase.auth) throw new Error("firebase.auth is not set");
    return firebase
      .auth()
      .signOut()
      .then(() => {
        window.location.replace(`/signin`);
      });
  });

  if (error) return <Wrapper>{error.message}</Wrapper>;
  if (!props) return <Wrapper>Loading ...</Wrapper>;
  return (
    <>
      <Box
        maxWidth={400}
        marginLeft="auto"
        marginRight="auto"
        display="flex"
        flexDirection="column"
      >
        <Wrapper>
          <CardContent>
            <ProjectList viewerRef={props.viewer} />
          </CardContent>
        </Wrapper>
        {props.viewer.role === "ADMIN" && (
          <Box mt={4} display="flex" flexDirection="column">
            <LinkButton color="primary" to="/projects/create" fullWidth>
              新規プロジェクト作成
            </LinkButton>
            <LinkButton color="primary" to="/users" fullWidth>
              ユーザー管理
            </LinkButton>
          </Box>
        )}
        <Box mt={4} display="flex" flexDirection="column">
          <Button color="primary" onClick={logout} disabled={state.loading}>
            ログアウトする
          </Button>
        </Box>
      </Box>
    </>
  );
};
