import { useCallback } from "react";
import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { useRelayEnvironment } from "relay-hooks";

import {
  UpdateDailyAuctionSettingInput,
  UpdateDailyAuctionSettingMutation,
} from "~/__relay_artifacts__/UpdateDailyAuctionSettingMutation.graphql";

const mutation = graphql`
  mutation UpdateDailyAuctionSettingMutation(
    $input: UpdateDailyAuctionSettingInput!
  ) {
    updateDailyAuctionSetting(input: $input) {
      dailyAuctionSetting {
        id
        date
        floorPrice
        platform
      }
    }
  }
`;

export const useUpdateDailyAuctionSettingMutation = () => {
  const environment = useRelayEnvironment();

  const updateDailyAuctionSettingMutation = useCallback(
    (input: UpdateDailyAuctionSettingInput) =>
      commitMutation<UpdateDailyAuctionSettingMutation>(environment, {
        mutation,
        variables: { input },
      }),
    [environment]
  );

  return { updateDailyAuctionSettingMutation };
};
