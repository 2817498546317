import firebase from "@firebase/app";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Typography,
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { AccountCircle as AccountCircleIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { Formik, FormikConfig } from "formik";
import { FC, useCallback, useState } from "react";
import { FragmentRef, graphql } from "react-relay";
import { useMemo } from "reactn";
import * as yup from "yup";

import { AccountButton_viewer } from "~/__relay_artifacts__/AccountButton_viewer.graphql";
import { AccountForm } from "~/components/AccountForm";
import { useFragment } from "~/lib/relay-hooks";

type Props = {
  viewerRef: FragmentRef<AccountButton_viewer>;
};

type FormValues = {
  email: string;
};

const validationSchema = yup.object().shape({
  email: yup.string().required(),
});

const useStyles = makeStyles((theme: Theme) => ({
  button: {},
  paper: {
    position: "absolute",
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
}));

const userFragment = graphql`
  fragment AccountButton_viewer on User {
    id
    email
  }
`;

export const AccountButton: FC<Props> = ({ viewerRef }) => {
  const classes = useStyles();
  const [modalOpen, openModal] = useState(false);
  const viewer = useFragment<AccountButton_viewer>(userFragment, viewerRef);

  const initialValues = useMemo(
    () => ({
      email: viewer.email,
    }),
    [viewer.email]
  );

  const handleSubmit = useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        if (!firebase.auth) throw new Error("firebase.auth is not set");
        const currentUser = firebase.auth().currentUser;
        if (!currentUser) return;
        return currentUser.updateEmail(values.email);
      } catch (err) {
        setErrors(err);
      }
    },
    []
  );

  return (
    <>
      <ListItem button onClick={() => openModal(true)}>
        <ListItemIcon>
          <AccountCircleIcon />
        </ListItemIcon>
        <ListItemText primary={viewer.email} />
      </ListItem>
      <Modal open={modalOpen} onClose={() => openModal(false)}>
        <div className={classes.paper}>
          <Typography variant="subtitle1">アカウント編集</Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <AccountForm />
          </Formik>
        </div>
      </Modal>
    </>
  );
};
