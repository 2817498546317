import { Card, CardContent, CardHeader } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { Formik, FormikConfig } from "formik";
import { FC, useCallback } from "react";
import { useHistory } from "react-router";

import { FormValues, ProjectForm, schema } from "~/components/ProjectForm";
import { extractValidationErrors } from "~/lib/mutationUtils";
import { useCreateProjectMutation } from "~/mutations/CreateProjectMutation";

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: "auto",
    display: "block",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  card: {
    marginTop: theme.spacing(8),
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
}));

const initialValues = {
  name: "",
};

export const ProjectCreateContainer: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { createProjectMutation } = useCreateProjectMutation();

  const handleSubmit = useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const { createProject } = await createProjectMutation(values);
        const newProject = createProject?.project;
        if (!newProject) throw new Error("assertion failed");
        history.push(`/projects/${newProject.id}`);
      } catch (err) {
        const errors = extractValidationErrors<FormValues>(err);
        if (errors) setErrors(errors);
      }
    },
    [createProjectMutation, history]
  );

  return (
    <main className={classes.main}>
      <Card className={classes.card}>
        <CardHeader title="プロジェクトの作成" />
        <CardContent>
          <Formik<FormValues>
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            <ProjectForm />
          </Formik>
        </CardContent>
      </Card>
    </main>
  );
};
