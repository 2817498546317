/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdUnitEditButton_adUnit = {
    readonly " $fragmentRefs": FragmentRefs<"AdUnitEditForm_adUnit">;
    readonly " $refType": "AdUnitEditButton_adUnit";
};
export type AdUnitEditButton_adUnit$data = AdUnitEditButton_adUnit;
export type AdUnitEditButton_adUnit$key = {
    readonly " $data"?: AdUnitEditButton_adUnit$data;
    readonly " $fragmentRefs": FragmentRefs<"AdUnitEditButton_adUnit">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdUnitEditButton_adUnit",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AdUnitEditForm_adUnit"
    }
  ],
  "type": "AdUnit",
  "abstractKey": null
};
(node as any).hash = '87a6e535745cf3060459d1a997a8c6dd';
export default node;
