import { ListItemText } from "@material-ui/core";
import { FC } from "react";
import { FragmentRef, graphql } from "react-relay";

import { ProjectListItem_project } from "~/__relay_artifacts__/ProjectListItem_project.graphql";
import { LinkListItem } from "~/components/atoms/LinkListItem";
import { useFragment } from "~/lib/relay-hooks";

type Props = {
  number: number;
  projectRef: FragmentRef<ProjectListItem_project>;
};

const projectFragment = graphql`
  fragment ProjectListItem_project on Project {
    id
    name
    createdAt
    updatedAt
  }
`;

export const ProjectListItem: FC<Props> = ({ projectRef }) => {
  const project = useFragment<ProjectListItem_project>(
    projectFragment,
    projectRef
  );

  return (
    <LinkListItem
      button
      key={project.id}
      to={`/projects/${project.id}/publisher_report`}
    >
      <ListItemText primary={project.name} />
    </LinkListItem>
  );
};
