import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import * as DateFns from "date-fns";
import { FC, useEffect, useMemo, useState } from "react";
import {
  RelayPaginationProp,
  createPaginationContainer,
  graphql,
} from "react-relay";
import { Link } from "react-router-dom";

import { AppListTableCard_project } from "~/__relay_artifacts__/AppListTableCard_project.graphql";

type Props = {
  relay: RelayPaginationProp;
  project: AppListTableCard_project;
};

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    minWidth: 1200,
  },
  loadButton: {
    marginTop: 5,
    marginBottom: 5,
  },
  root: {
    margin: theme.spacing(3),
  },
  wrapper: {
    overflowX: "auto",
  },
}));

const AppListTableCard: FC<Props> = ({ project, relay }) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(100);
  const totalCount = project.apps.totalCount;

  const apps = useMemo(() => {
    const edges = project?.apps.edges || [];
    const from = page * perPage;
    const to = page * perPage + perPage;
    return edges.slice(from, to).map((edge) => {
      if (!edge?.node) throw new Error("assertion failed");
      return edge.node;
    });
  }, [page, perPage, project?.apps.edges]);

  useEffect(() => {
    relay.loadMore(perPage, () => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography variant="subtitle1" color="inherit">
          登録済みアプリの一覧
        </Typography>
      </Toolbar>
      <div className={classes.wrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>名前</TableCell>
              <TableCell>プラットフォーム</TableCell>
              <TableCell>URL</TableCell>
              <TableCell>作成日</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {apps.map((app) => (
              <TableRow key={app.id}>
                <TableCell>
                  <Link to={`apps/${app.id}`}>{app.name}</Link>
                </TableCell>
                <TableCell>{app.platform}</TableCell>
                <TableCell>{app.url}</TableCell>
                <TableCell>
                  {DateFns.format(
                    DateFns.parseISO(app.createdAt),
                    "yyyy/MM/dd"
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={totalCount}
          rowsPerPage={perPage}
          page={page}
          onPageChange={(_, page) => setPage(page)}
          onRowsPerPageChange={(event) =>
            setPerPage(Number(event.target.value))
          }
        />
      </div>
    </Paper>
  );
};

export default createPaginationContainer(
  AppListTableCard,
  {
    project: graphql`
      fragment AppListTableCard_project on Project {
        id
        apps(first: $first, after: $after)
          @connection(key: "AppListTableCard_apps", filters: []) {
          edges {
            node {
              id
              name
              platform
              url
              createdAt
              updatedAt
            }
          }
          totalCount
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
        }
      }
    `,
  },
  {
    getConnectionFromProps(props) {
      return props.project.apps as any;
    },
    getVariables(props, { count, cursor }) {
      return {
        projectId: props.project.id,
        first: count,
        after: cursor,
      };
    },
    query: graphql`
      query AppListTableCard_Query(
        $projectId: ID!
        $first: Int
        $after: String
      ) {
        project(id: $projectId) {
          ...AppListTableCard_project
        }
      }
    `,
  }
);
