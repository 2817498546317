import { FC } from "react";

import { ModalButton } from "~/components/ModalButton";
import { UserCreateForm } from "~/containers/UserCreateForm";

export const UserCreateButton: FC = ({ children }) => (
  <ModalButton
    render={({ close }) => (
      <UserCreateForm title="ユーザー作成" onSubmitCompleted={close} />
    )}
  >
    {children}
  </ModalButton>
);
