import { Icon, Tab, Tabs } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { FC, useCallback, useState } from "react";
import { createFragmentContainer, graphql } from "react-relay";
import { useHistory, useParams } from "react-router";

import { AppDetail_app } from "~/__relay_artifacts__/AppDetail_app.graphql";
import { AdUnitListTableCard } from "~/components/AdUnitListTableCard";
import AppDetailCard from "~/components/AppDetailCard";
import { PageHeader } from "~/components/PageHeader";
import { ConfirmButton } from "~/components/atoms/ConfirmButton";
import { LinkButton } from "~/components/atoms/LinkButton";
import { useDeleteAppMutation } from "~/mutations/DeleteAppMutation";

type Props = {
  app: AppDetail_app;
};

type RouterParams = {
  id: string;
  projectId: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  paper: {
    margin: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
}));

const AppDetail: FC<Props> = ({ app }) => {
  const classes = useStyles();
  const history = useHistory();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { projectId, id: appId } = useParams<RouterParams>();
  const { deleteAppMutation } = useDeleteAppMutation();

  const handleDeleteApp = useCallback(async () => {
    await deleteAppMutation({ appId });
    history.push(`/projects/${projectId}/apps`);
  }, [deleteAppMutation, appId, history, projectId]);

  return (
    <>
      <PageHeader
        title={app.name}
        backTo={`/projects/${projectId}/apps`}
        tabs={
          <Tabs
            value={selectedTabIndex}
            onChange={(_, v) => setSelectedTabIndex(v)}
          >
            <Tab label="広告枠" />
            <Tab label="設定" />
          </Tabs>
        }
        breadcrumbs={[
          { title: "アプリ一覧", link: `/projects/${projectId}/apps` },
          { title: "アプリ詳細", link: `/projects/${projectId}/apps/${appId}` },
        ]}
      >
        <LinkButton
          color="inherit"
          key="edit"
          className={classes.button}
          to={`${appId}/edit`}
        >
          <Icon className={classes.leftIcon}>edit</Icon>編集
        </LinkButton>
        <ConfirmButton
          key="delete"
          color="inherit"
          confirmTitle="このアプリケーションを削除しますか？"
          onAgree={handleDeleteApp}
        >
          <Icon className={classes.leftIcon}>delete</Icon>削除
        </ConfirmButton>
      </PageHeader>
      <div className={classes.root}>
        {selectedTabIndex === 0 && <AdUnitListTableCard appRef={app} />}
        {selectedTabIndex === 1 && <AppDetailCard app={app} />}
      </div>
    </>
  );
};

export default createFragmentContainer(AppDetail, {
  app: graphql`
    fragment AppDetail_app on App {
      name
      ...AppDetailCard_app
      ...AdUnitListTableCard_app
    }
  `,
});
