import {
  Icon,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { FC, useEffect, useMemo, useState } from "react";
import {
  RelayPaginationProp,
  createPaginationContainer,
  graphql,
} from "react-relay";

import { UserListTableCard_root } from "~/__relay_artifacts__/UserListTableCard_root.graphql";
import { UserCreateButton } from "~/containers/UserCreateButton";
import { UserDeleteButton } from "~/containers/UserDeleteButton";

type Props = {
  relay: RelayPaginationProp;
  root: UserListTableCard_root;
};

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    minWidth: 1200,
  },
  root: {
    margin: theme.spacing(3),
  },
  wrapper: {
    overflowX: "auto",
  },
}));

export const UserListTableCard: FC<Props> = ({ root, relay }) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(100);
  const totalCount = root.users && root.users.totalCount;

  const users = useMemo(() => {
    const edges = root.users?.edges || [];
    const from = page * perPage;
    const to = page * perPage + perPage;
    return edges.slice(from, to).map((edge) => {
      const node = edge?.node;
      if (!node) throw new Error("assertion failed");
      const isEditable =
        root.viewer.role === "ADMIN" && root.viewer.id !== node.id;
      return { ...node, isEditable };
    });
  }, [page, perPage, root.users, root.viewer.id, root.viewer.role]);

  useEffect(() => {
    relay.loadMore(perPage, () => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography variant="subtitle1" color="inherit">
          ユーザー一覧
        </Typography>
        <UserCreateButton>
          <Icon>add</Icon>
          <span>作成する</span>
        </UserCreateButton>
      </Toolbar>
      <div className={classes.wrapper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>役割</TableCell>
              <TableCell>アクション</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.role}</TableCell>
                <TableCell>
                  {user.isEditable && <UserDeleteButton userId={user.id} />}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={totalCount}
          rowsPerPage={perPage}
          page={page}
          onPageChange={(_, page) => setPage(page)}
          onRowsPerPageChange={(e) => setPerPage(Number(e.target.value))}
        />
      </div>
    </Paper>
  );
};

export default createPaginationContainer(
  UserListTableCard,
  {
    root: graphql`
      fragment UserListTableCard_root on Query {
        viewer {
          id
          role
          email
        }
        users(first: $count, after: $cursor, orderBy: $orderBy)
          @connection(key: "UserListTableCard_users", filters: []) {
          edges {
            node {
              id
              email
              role
            }
          }
          totalCount
        }
      }
    `,
  },
  {
    getConnectionFromProps(props) {
      return props.root && (props.root.users as any);
    },
    getVariables(_, { count, cursor }, fragmentVariables) {
      return {
        count,
        cursor,
        orderBy: fragmentVariables.orderBy,
      };
    },
    query: graphql`
      query UserListTableCard_Query(
        $count: Int!
        $cursor: String
        $orderBy: UserOrder
      ) {
        ...UserListTableCard_root
      }
    `,
  }
);
