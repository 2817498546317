import * as React from "react";

type State = {
  error: Error | null;
};

export class ErrorBoundary extends React.Component {
  state: State = { error: null };

  componentDidCatch(error: Error) {
    this.setState({ error });
  }

  render() {
    return this.state.error ? (
      <div>
        <h2>{this.state.error.toString()}</h2>
      </div>
    ) : (
      this.props.children
    );
  }
}
