import { FC } from "react";
import { graphql } from "react-relay";
import { useParams } from "react-router";
import { useQuery } from "relay-hooks";

import { AppEdit_Query } from "~/__relay_artifacts__/AppEdit_Query.graphql";
import { PageHeader } from "~/components/PageHeader";
import { AppEditForm } from "~/containers/AppEditForm";

const query = graphql`
  query AppEdit_Query($projectId: ID!, $appId: ID!) {
    project(id: $projectId) {
      app(id: $appId) {
        ...AppEditForm_app
      }
    }
  }
`;

export const AppEditContainer: FC = () => {
  const { id, projectId } = useParams<{ id: string; projectId: string }>();
  const { props, error } = useQuery<AppEdit_Query>(
    query,
    { projectId, appId: id },
    { networkCacheConfig: { force: true } }
  );

  return (
    <>
      <PageHeader title="アプリの編集" />
      {(() => {
        if (error) return <div>{error.message}</div>;
        if (!props) return <div>Loading</div>;
        if (!props.project?.app) return <div>not found</div>;
        return <AppEditForm appRef={props.project.app} />;
      })()}
    </>
  );
};
