import { FC } from "react";
import { graphql } from "react-relay";
import { Redirect, useParams } from "react-router";
import { useQuery } from "relay-hooks";

import { ProjectRoot_Query } from "~/__relay_artifacts__/ProjectRoot_Query.graphql";

const query = graphql`
  query ProjectRoot_Query($projectId: ID!) {
    project(id: $projectId) {
      id
    }
  }
`;

const useProject = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { props } = useQuery<ProjectRoot_Query>(query, { projectId });
  return {
    loading: !props,
    project: props?.project,
  };
};

export const ProjectRoot: FC = ({ children }) => {
  const { loading, project } = useProject();
  if (loading) return <>Loading...</>;
  if (!project) return <Redirect to="/" />;
  return <>{children}</>;
};
