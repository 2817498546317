import { List, ListItem, ListItemText, Paper } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import * as DateFns from "date-fns";
import { FC } from "react";
import { createRefetchContainer, graphql } from "react-relay";

import { AppDetailCard_app } from "~/__relay_artifacts__/AppDetailCard_app.graphql";

type Props = {
  app: AppDetailCard_app;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
  },
}));

const AppDetailCard: FC<Props> = ({ app }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <List>
        <ListItem>
          <ListItemText primary="名前" secondary={app.name} />
        </ListItem>
        <ListItem>
          <ListItemText primary="プラットフォーム" secondary={app.platform} />
        </ListItem>
        <ListItem>
          <ListItemText primary="URL" secondary={app.url} />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="作成日"
            secondary={DateFns.format(
              DateFns.parseISO(app.createdAt),
              "yyyy/MM/dd"
            )}
          />
        </ListItem>
      </List>
    </Paper>
  );
};

export default createRefetchContainer(
  AppDetailCard,
  {
    app: graphql`
      fragment AppDetailCard_app on App {
        id
        name
        platform
        url
        createdAt
        updatedAt
      }
    `,
  },
  graphql`
    query AppDetailCard_Query($projectId: ID!, $appId: ID!) {
      project(id: $projectId) {
        app(id: $appId) {
          ...AppDetailCard_app
        }
      }
    }
  `
);
