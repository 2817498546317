import { useCallback } from "react";
import { graphql, useRelayEnvironment } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  CreateAppInput,
  CreateAppMutation,
} from "~/__relay_artifacts__/CreateAppMutation.graphql";

const mutation = graphql`
  mutation CreateAppMutation($input: CreateAppInput!) {
    createApp(input: $input) {
      app {
        id
      }
    }
  }
`;

export const useCreateAppMutation = () => {
  const environment = useRelayEnvironment();

  const createAppMutation = useCallback(
    (input: CreateAppInput) =>
      commitMutation<CreateAppMutation>(environment, {
        mutation,
        variables: { input },
      }),
    [environment]
  );

  return { createAppMutation };
};
