/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Platform = "ANDROID" | "IOS" | "WEB" | "%future added value";
export type DailyAuctionSettingListTableCard_auctionSetting = {
    readonly id: string;
    readonly platform: Platform;
    readonly dailyAuctionSettings: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly platform: Platform;
                readonly date: string;
                readonly floorPrice: number;
                readonly " $fragmentRefs": FragmentRefs<"DailyAuctionSettingEditForm_dailyAuctionSetting">;
            } | null;
        } | null> | null;
    };
    readonly " $refType": "DailyAuctionSettingListTableCard_auctionSetting";
};
export type DailyAuctionSettingListTableCard_auctionSetting$data = DailyAuctionSettingListTableCard_auctionSetting;
export type DailyAuctionSettingListTableCard_auctionSetting$key = {
    readonly " $data"?: DailyAuctionSettingListTableCard_auctionSetting$data;
    readonly " $fragmentRefs": FragmentRefs<"DailyAuctionSettingListTableCard_auctionSetting">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "platform",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "dailyAuctionSettings"
        ]
      }
    ]
  },
  "name": "DailyAuctionSettingListTableCard_auctionSetting",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": "dailyAuctionSettings",
      "args": null,
      "concreteType": "DailyAuctionSettingConnection",
      "kind": "LinkedField",
      "name": "__DailyAuctionSettingListTableCard_dailyAuctionSettings_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DailyAuctionSettingEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DailyAuctionSetting",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "date",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "floorPrice",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "DailyAuctionSettingEditForm_dailyAuctionSetting"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AuctionSetting",
  "abstractKey": null
};
})();
(node as any).hash = '88fdf850881fdeb144e1b262f2cb13e5';
export default node;
