import { FC } from "react";
import { graphql } from "react-relay";
import { useQuery } from "relay-hooks";

import { UserList_Query } from "~/__relay_artifacts__/UserList_Query.graphql";
import { PageHeader } from "~/components/PageHeader";
import UserListTableCard from "~/components/UserListTableCard";

const query = graphql`
  query UserList_Query($count: Int, $cursor: String, $orderBy: UserOrder) {
    ...UserListTableCard_root
  }
`;

export const UserListContainer: FC = () => {
  const { props, error } = useQuery<UserList_Query>(query, {
    count: 100,
    orderBy: { field: "CREATED_AT", direction: "DESC" },
  });

  return (
    <>
      <PageHeader title="ユーザー管理" backTo="/" />
      {(() => {
        if (error) return <div>{error.message}</div>;
        if (!props) return <div>Loading...</div>;
        return <UserListTableCard root={props} />;
      })()}
    </>
  );
};
