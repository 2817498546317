import { Paper, Tab, Tabs, Toolbar, Typography } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import * as R from "ramda";
import { FC, useMemo, useState } from "react";
import { graphql } from "react-relay";
import { useParams } from "react-router";

import { AdUnitDetail_Query } from "~/__relay_artifacts__/AdUnitDetail_Query.graphql";
import { AdUnitDetail_adUnit } from "~/__relay_artifacts__/AdUnitDetail_adUnit.graphql";
import { PageHeader } from "~/components/PageHeader";
import { useFragment } from "~/lib/relay-hooks";

import { DailyAuctionSettingListTableCard } from "./DailyAuctionSettingListTableCard";

type Props = {
  adUnitRef: NonNullable<AdUnitDetail_Query["response"]["project"]>["adUnit"];
};

const adUnitFragment = graphql`
  fragment AdUnitDetail_adUnit on AdUnit {
    id
    name
    app {
      id
    }
    auctionSettings {
      edges {
        node {
          id
          platform
          floorPrice
          ...DailyAuctionSettingListTableCard_auctionSetting
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  paper: {
    margin: theme.spacing(3),
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    width: "100%",
    overflowX: "auto",
  },
  actions: {
    display: "flex",
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
}));

export const AdUnitDetail: FC<Props> = ({ adUnitRef }) => {
  const classes = useStyles();
  const adUnit = useFragment<AdUnitDetail_adUnit>(adUnitFragment, adUnitRef);
  const { projectId } = useParams<{ projectId: string }>();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const auctionSettings = useMemo(() => {
    const edges = adUnit.auctionSettings.edges || [];
    return edges.map((edge) => {
      if (!edge?.node) throw new Error("assertion failed");
      return edge.node;
    });
  }, [adUnit]);

  const iosAuctionSetting = useMemo(
    () =>
      R.filter(
        (auctionSetting) => auctionSetting.platform === "IOS",
        auctionSettings
      ),
    [auctionSettings]
  );

  const androidAuctionSetting = useMemo(
    () =>
      R.filter(
        (auctionSetting) => auctionSetting.platform === "ANDROID",
        auctionSettings
      ),
    [auctionSettings]
  );

  return (
    <>
      <PageHeader
        title={adUnit.name}
        backTo={`/projects/${projectId}/apps/${adUnit.app.id}`}
        tabs={
          <Tabs
            value={selectedTabIndex}
            onChange={(_, v) => setSelectedTabIndex(v)}
          >
            <Tab label="CPM設定(IOS)" />
            <Tab label="CPM設定(ANDROID)" />
          </Tabs>
        }
        breadcrumbs={[
          { title: "アプリ一覧", link: `/projects/${projectId}/apps` },
          {
            title: "アプリ詳細",
            link: `/projects/${projectId}/apps/${adUnit.app.id}`,
          },
          {
            title: "AdUnit詳細",
            link: `/projects/${projectId}/ad_units/${adUnit.id}`,
          },
        ]}
      />
      <div className={classes.root}>
        {selectedTabIndex === 0 &&
          (iosAuctionSetting.length > 0 ? (
            <DailyAuctionSettingListTableCard
              auctionSettingRef={iosAuctionSetting[0]}
            />
          ) : (
            <Paper className={classes.paper}>
              <Toolbar>
                <Typography variant="subtitle1" color="inherit">
                  CPMが設定されてません
                </Typography>
              </Toolbar>
            </Paper>
          ))}
        {selectedTabIndex === 1 &&
          (androidAuctionSetting.length > 0 ? (
            <DailyAuctionSettingListTableCard
              auctionSettingRef={androidAuctionSetting[0]}
            />
          ) : (
            <Paper className={classes.paper}>
              <Toolbar>
                <Typography variant="subtitle1" color="inherit">
                  CPMが設定されてません
                </Typography>
              </Toolbar>
            </Paper>
          ))}
      </div>
    </>
  );
};
