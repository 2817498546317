import { useSnackbar } from "notistack";
import { FC, useCallback } from "react";

import { ConfirmButton } from "~/components/atoms/ConfirmButton";
import { useDeleteAuctionSettingMutation } from "~/mutations/DeleteAuctionSettingMutation";

type Props = {
  adUnitId: string;
  auctionSettingId: string;
};

export const AuctionSettingDeleteButton: FC<Props> = ({
  auctionSettingId,
  adUnitId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { deleteAuctionSettingMutation } = useDeleteAuctionSettingMutation({
    adUnitId,
  });

  const handleDelete = useCallback(async () => {
    try {
      const { deleteAuctionSetting } = await deleteAuctionSettingMutation({
        auctionSettingId,
      });
      if (!deleteAuctionSetting?.deletedAuctionSettingId) {
        throw new Error("assertion failed");
      }
      enqueueSnackbar("オークション設定を削除しました。", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }, [deleteAuctionSettingMutation, auctionSettingId, enqueueSnackbar]);

  return (
    <ConfirmButton
      variant="outlined"
      color="secondary"
      confirmTitle="このオークション設定を削除しますか？"
      confirmContent=""
      onAgree={(changeModal) => {
        handleDelete().then(() => changeModal(false));
      }}
    >
      削除
    </ConfirmButton>
  );
};
