/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdPosition = "ABOVE_THE_FOLD" | "BELOW_THE_FOLD" | "DEPRECATED" | "FOOTER" | "FULL_SCREEN" | "HEADER" | "SIDEBAR" | "UNKNOWN" | "%future added value";
export type AdUnitListTableCard_app = {
    readonly id: string;
    readonly adUnits: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly name: string;
                readonly createdAt: string;
                readonly updatedAt: string;
                readonly width: number;
                readonly height: number;
                readonly adPosition: AdPosition;
                readonly tagId: string;
                readonly embedTag: string;
                readonly frequencyCapEnabled: boolean;
                readonly " $fragmentRefs": FragmentRefs<"AdUnitEditButton_adUnit" | "AdUnitTagCard_adUnit" | "AuctionSettingList_adUnit">;
            } | null;
        } | null> | null;
    };
    readonly " $refType": "AdUnitListTableCard_app";
};
export type AdUnitListTableCard_app$data = AdUnitListTableCard_app;
export type AdUnitListTableCard_app$key = {
    readonly " $data"?: AdUnitListTableCard_app$data;
    readonly " $fragmentRefs": FragmentRefs<"AdUnitListTableCard_app">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "first"
    },
    {
      "kind": "RootArgument",
      "name": "orderBy"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": [
          "adUnits"
        ]
      }
    ]
  },
  "name": "AdUnitListTableCard_app",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "adUnits",
      "args": null,
      "concreteType": "AdUnitConnection",
      "kind": "LinkedField",
      "name": "__AdUnitListTableCard_adUnits_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AdUnitEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AdUnit",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "updatedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "width",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "height",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "adPosition",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "tagId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "embedTag",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "frequencyCapEnabled",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AdUnitEditButton_adUnit"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AdUnitTagCard_adUnit"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AuctionSettingList_adUnit"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "App",
  "abstractKey": null
};
})();
(node as any).hash = 'bd454399048e4c28312781e6d5f82299';
export default node;
