/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Platform = "ANDROID" | "IOS" | "WEB" | "%future added value";
export type AppEditForm_app = {
    readonly id: string;
    readonly name: string;
    readonly platform: Platform;
    readonly url: string;
    readonly geoLocationEnabled: boolean;
    readonly frequencyCapEnabled: boolean;
    readonly " $refType": "AppEditForm_app";
};
export type AppEditForm_app$data = AppEditForm_app;
export type AppEditForm_app$key = {
    readonly " $data"?: AppEditForm_app$data;
    readonly " $fragmentRefs": FragmentRefs<"AppEditForm_app">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppEditForm_app",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "platform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "geoLocationEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "frequencyCapEnabled",
      "storageKey": null
    }
  ],
  "type": "App",
  "abstractKey": null
};
(node as any).hash = '638f3d14c1bb936db51e207acf7fc171';
export default node;
