/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AuctionSettingEditButton_auctionSetting = {
    readonly " $fragmentRefs": FragmentRefs<"AuctionSettingEditForm_auctionSetting">;
    readonly " $refType": "AuctionSettingEditButton_auctionSetting";
};
export type AuctionSettingEditButton_auctionSetting$data = AuctionSettingEditButton_auctionSetting;
export type AuctionSettingEditButton_auctionSetting$key = {
    readonly " $data"?: AuctionSettingEditButton_auctionSetting$data;
    readonly " $fragmentRefs": FragmentRefs<"AuctionSettingEditButton_auctionSetting">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AuctionSettingEditButton_auctionSetting",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AuctionSettingEditForm_auctionSetting"
    }
  ],
  "type": "AuctionSetting",
  "abstractKey": null
};
(node as any).hash = '815edd0c1ba5fdbe0e565474bacc9d12';
export default node;
