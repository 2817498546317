import "firebase/auth";

import firebase from "firebase/app";
import { FC, useState } from "react";
import { useMount } from "react-use";

import { FIREBASE_ID_TOKEN } from "~/lib/constants";
import { Routes } from "~/routes";

export const App: FC = () => {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useMount(() => {
    if (firebase.auth === undefined) return;
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        setIsSignedIn(false);
        setIsLoading(false);
        return;
      }
      user.getIdToken(true).then((idToken) => {
        localStorage.setItem(FIREBASE_ID_TOKEN, idToken);
        setIsSignedIn(true);
        setIsLoading(false);
      });
    });
  });

  return isLoading ? (
    <div>Now Loading...</div>
  ) : (
    <Routes isSignedIn={isSignedIn} />
  );
};
