/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ProjectDrawerMenuList_project = {
    readonly id: string;
    readonly name: string;
    readonly viewerCanReadApp: boolean;
    readonly viewerCanAdministrate: boolean;
    readonly " $refType": "ProjectDrawerMenuList_project";
};
export type ProjectDrawerMenuList_project$data = ProjectDrawerMenuList_project;
export type ProjectDrawerMenuList_project$key = {
    readonly " $data"?: ProjectDrawerMenuList_project$data;
    readonly " $fragmentRefs": FragmentRefs<"ProjectDrawerMenuList_project">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectDrawerMenuList_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanReadApp",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanAdministrate",
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};
(node as any).hash = 'd1625d034e04f175b6f8e7a724bf133d';
export default node;
