import {
  Box,
  Divider,
  List,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import {
  Apps as AppsIcon,
  Home as HomeIcon,
  InsertChart as InsertChartIcon,
  SupervisorAccount as SupervisorAccountIcon,
} from "@material-ui/icons";
import { FC } from "react";
import { createFragmentContainer, graphql } from "react-relay";
import { useLocation } from "react-router";

import { ProjectDrawerMenuList_project } from "~/__relay_artifacts__/ProjectDrawerMenuList_project.graphql";
import { ProjectDrawerMenuList_viewer } from "~/__relay_artifacts__/ProjectDrawerMenuList_viewer.graphql";
import { AccountButton } from "~/components/AccountButton";
import { LogoutButton } from "~/components/LogoutButton";
import { LinkListItem } from "~/components/atoms/LinkListItem";

type Props = {
  project: ProjectDrawerMenuList_project;
  viewer: ProjectDrawerMenuList_viewer;
};

const matches = (path: string, re: RegExp) => !!path.match(re);

const ProjectDrawerMenuList: FC<Props> = ({ project, viewer }) => {
  const location = useLocation();

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <List>
        <AccountButton viewerRef={viewer} />
        <Divider />
        {project.viewerCanAdministrate && (
          <>
            <LinkListItem
              key="home"
              to={`/projects/${project.id}/home`}
              button
              selected={matches(location.pathname, /home/)}
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="ホーム" />
            </LinkListItem>
            <LinkListItem
              key="app"
              to={`/projects/${project.id}/apps`}
              button
              selected={matches(location.pathname, /apps/)}
            >
              <ListItemIcon>
                <AppsIcon />
              </ListItemIcon>
              <ListItemText primary="アプリ" />
            </LinkListItem>
          </>
        )}
        {project.viewerCanReadApp && (
          <LinkListItem
            key="publisher_report"
            to={`/projects/${project.id}/publisher_report`}
            button
            selected={matches(location.pathname, /publisher_report/)}
          >
            <ListItemIcon>
              <InsertChartIcon />
            </ListItemIcon>
            <ListItemText primary="メディアレポート" />
          </LinkListItem>
        )}
      </List>
      <Box flexGrow={1} />
      <List>
        {viewer.role === "ADMIN" && (
          <List>
            <LinkListItem key="users" to={`/users`} button>
              <ListItemIcon>
                <SupervisorAccountIcon />
              </ListItemIcon>
              <ListItemText primary="ユーザー管理" />
            </LinkListItem>
          </List>
        )}
        <LogoutButton />
      </List>
    </Box>
  );
};

export default createFragmentContainer(ProjectDrawerMenuList, {
  project: graphql`
    fragment ProjectDrawerMenuList_project on Project {
      id
      name
      viewerCanReadApp
      viewerCanAdministrate
    }
  `,
  viewer: graphql`
    fragment ProjectDrawerMenuList_viewer on User {
      ...AccountButton_viewer
      role
      projects {
        edges {
          node {
            id
            name
            viewerCanAdministrate
          }
        }
      }
    }
  `,
});
