import { FC } from "react";
import { graphql } from "react-relay";
import { useParams } from "react-router";
import { useQuery } from "relay-hooks";

import { ProjectHeader_Query } from "~/__relay_artifacts__/ProjectHeader_Query.graphql";
import ProjectHeader from "~/components/ProjectHeader";

const query = graphql`
  query ProjectHeader_Query($projectId: ID!) {
    project(id: $projectId) {
      ...ProjectHeader_project
    }
    viewer {
      ...ProjectHeader_viewer
    }
  }
`;

export const ProjectHeaderContainer: FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { props, error } = useQuery<ProjectHeader_Query>(
    query,
    { projectId },
    { networkCacheConfig: { force: true } }
  );

  if (error) return <div>{error.message}</div>;
  if (!props) return <div>Loading</div>;
  if (!props.viewer || !props.project) return <div>not found</div>;
  return <ProjectHeader project={props.project} viewer={props.viewer} />;
};
