/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdUnitOrderField = "CREATED_AT" | "NAME" | "STATUS" | "%future added value";
export type OrderDirection = "ASC" | "DESC" | "%future added value";
export type AdUnitOrder = {
    direction: OrderDirection;
    field: AdUnitOrderField;
};
export type AppDetail_QueryVariables = {
    projectId: string;
    appId: string;
    first?: number | null;
    after?: string | null;
    orderBy?: AdUnitOrder | null;
};
export type AppDetail_QueryResponse = {
    readonly project: {
        readonly app: {
            readonly " $fragmentRefs": FragmentRefs<"AppDetail_app">;
        } | null;
    } | null;
};
export type AppDetail_Query = {
    readonly response: AppDetail_QueryResponse;
    readonly variables: AppDetail_QueryVariables;
};



/*
query AppDetail_Query(
  $projectId: ID!
  $appId: ID!
  $first: Int
  $after: String
  $orderBy: AdUnitOrder
) {
  project(id: $projectId) {
    app(id: $appId) {
      ...AppDetail_app
      id
    }
    id
  }
}

fragment AdUnitEditButton_adUnit on AdUnit {
  ...AdUnitEditForm_adUnit
}

fragment AdUnitEditForm_adUnit on AdUnit {
  id
  name
  width
  height
  adPosition
  frequencyCapEnabled
  tagId
  passbackIosTag
  passbackAndroidTag
}

fragment AdUnitListTableCard_app on App {
  id
  adUnits(first: $first, after: $after, orderBy: $orderBy) {
    edges {
      node {
        id
        name
        createdAt
        updatedAt
        width
        height
        adPosition
        tagId
        embedTag
        frequencyCapEnabled
        ...AdUnitEditButton_adUnit
        ...AdUnitTagCard_adUnit
        ...AuctionSettingList_adUnit
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment AdUnitTagCard_adUnit on AdUnit {
  id
  embedTag
}

fragment AppDetailCard_app on App {
  id
  name
  platform
  url
  createdAt
  updatedAt
}

fragment AppDetail_app on App {
  name
  ...AppDetailCard_app
  ...AdUnitListTableCard_app
}

fragment AuctionSettingEditButton_auctionSetting on AuctionSetting {
  ...AuctionSettingEditForm_auctionSetting
}

fragment AuctionSettingEditForm_auctionSetting on AuctionSetting {
  id
  platform
  floorPrice
}

fragment AuctionSettingList_adUnit on AdUnit {
  id
  auctionSettings(first: 100) {
    edges {
      node {
        id
        platform
        floorPrice
        ...AuctionSettingEditButton_auctionSetting
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "appId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v6 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "appId"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "platform",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v12 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  }
],
v13 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
],
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AppDetail_Query",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "App",
            "kind": "LinkedField",
            "name": "app",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AppDetail_app"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "AppDetail_Query",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "App",
            "kind": "LinkedField",
            "name": "app",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              },
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": (v12/*: any*/),
                "concreteType": "AdUnitConnection",
                "kind": "LinkedField",
                "name": "adUnits",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AdUnitEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AdUnit",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v7/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "width",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "height",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "adPosition",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "tagId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "embedTag",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "frequencyCapEnabled",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "passbackIosTag",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "passbackAndroidTag",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": (v13/*: any*/),
                            "concreteType": "AuctionSettingConnection",
                            "kind": "LinkedField",
                            "name": "auctionSettings",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AuctionSettingEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AuctionSetting",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v8/*: any*/),
                                      (v9/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "floorPrice",
                                        "storageKey": null
                                      },
                                      (v14/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v15/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v16/*: any*/)
                            ],
                            "storageKey": "auctionSettings(first:100)"
                          },
                          {
                            "alias": null,
                            "args": (v13/*: any*/),
                            "filters": null,
                            "handle": "connection",
                            "key": "AuctionSettingList_auctionSettings",
                            "kind": "LinkedHandle",
                            "name": "auctionSettings"
                          },
                          (v14/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v16/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v12/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "AdUnitListTableCard_adUnits",
                "kind": "LinkedHandle",
                "name": "adUnits"
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0273f3eba8c26171e09abac8628c6330",
    "id": null,
    "metadata": {},
    "name": "AppDetail_Query",
    "operationKind": "query",
    "text": "query AppDetail_Query(\n  $projectId: ID!\n  $appId: ID!\n  $first: Int\n  $after: String\n  $orderBy: AdUnitOrder\n) {\n  project(id: $projectId) {\n    app(id: $appId) {\n      ...AppDetail_app\n      id\n    }\n    id\n  }\n}\n\nfragment AdUnitEditButton_adUnit on AdUnit {\n  ...AdUnitEditForm_adUnit\n}\n\nfragment AdUnitEditForm_adUnit on AdUnit {\n  id\n  name\n  width\n  height\n  adPosition\n  frequencyCapEnabled\n  tagId\n  passbackIosTag\n  passbackAndroidTag\n}\n\nfragment AdUnitListTableCard_app on App {\n  id\n  adUnits(first: $first, after: $after, orderBy: $orderBy) {\n    edges {\n      node {\n        id\n        name\n        createdAt\n        updatedAt\n        width\n        height\n        adPosition\n        tagId\n        embedTag\n        frequencyCapEnabled\n        ...AdUnitEditButton_adUnit\n        ...AdUnitTagCard_adUnit\n        ...AuctionSettingList_adUnit\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment AdUnitTagCard_adUnit on AdUnit {\n  id\n  embedTag\n}\n\nfragment AppDetailCard_app on App {\n  id\n  name\n  platform\n  url\n  createdAt\n  updatedAt\n}\n\nfragment AppDetail_app on App {\n  name\n  ...AppDetailCard_app\n  ...AdUnitListTableCard_app\n}\n\nfragment AuctionSettingEditButton_auctionSetting on AuctionSetting {\n  ...AuctionSettingEditForm_auctionSetting\n}\n\nfragment AuctionSettingEditForm_auctionSetting on AuctionSetting {\n  id\n  platform\n  floorPrice\n}\n\nfragment AuctionSettingList_adUnit on AdUnit {\n  id\n  auctionSettings(first: 100) {\n    edges {\n      node {\n        id\n        platform\n        floorPrice\n        ...AuctionSettingEditButton_auctionSetting\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f4a64f548516ed18c6678ef183af2d14';
export default node;
