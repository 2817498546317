import { setGlobal } from "reactn";

import { PublisherReportFormValues } from "./pages/PublisherReport";

type PublisherReportCache = {
  [projectId: string]: PublisherReportFormValues | null;
};

declare module "reactn/default" {
  export interface State {
    publisherReportCache: PublisherReportCache;
  }
}

// NOTE: set initial state
setGlobal({
  publisherReportCache: {},
});
