import { useCallback } from "react";
import { graphql, useRelayEnvironment } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  UpdateAuctionSettingInput,
  UpdateAuctionSettingMutation,
} from "~/__relay_artifacts__/UpdateAuctionSettingMutation.graphql";

const mutation = graphql`
  mutation UpdateAuctionSettingMutation($input: UpdateAuctionSettingInput!) {
    updateAuctionSetting(input: $input) {
      auctionSetting {
        id
        platform
        floorPrice
      }
    }
  }
`;

export const useUpdateAuctionSettingMutation = () => {
  const environment = useRelayEnvironment();

  const updateAuctionSettingMutation = useCallback(
    (input: UpdateAuctionSettingInput) =>
      commitMutation<UpdateAuctionSettingMutation>(environment, {
        mutation,
        variables: { input },
      }),
    [environment]
  );

  return { updateAuctionSettingMutation };
};
