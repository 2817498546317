import { Icon } from "@material-ui/core";
import { FC } from "react";

import { ModalButton } from "~/components/ModalButton";
import { AuctionSettingCreateForm } from "~/containers/AuctionSettingCreateForm";

type Props = {
  adUnitId: string;
};

export const AuctionSettingCreateButton: FC<Props> = ({ adUnitId }) => (
  <ModalButton
    render={({ close }) => (
      <AuctionSettingCreateForm adUnitId={adUnitId} onSubmitCompleted={close} />
    )}
  >
    <Icon>add</Icon> 新規作成
  </ModalButton>
);
