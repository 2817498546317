/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MembershipRoleEditButton_membership = {
    readonly " $fragmentRefs": FragmentRefs<"MembershipRoleEditForm_membership">;
    readonly " $refType": "MembershipRoleEditButton_membership";
};
export type MembershipRoleEditButton_membership$data = MembershipRoleEditButton_membership;
export type MembershipRoleEditButton_membership$key = {
    readonly " $data"?: MembershipRoleEditButton_membership$data;
    readonly " $fragmentRefs": FragmentRefs<"MembershipRoleEditButton_membership">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MembershipRoleEditButton_membership",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MembershipRoleEditForm_membership"
    }
  ],
  "type": "Membership",
  "abstractKey": null
};
(node as any).hash = 'af24748cb109e8e65d0062b41e48b1a1';
export default node;
